import { useContext } from "react";
import { HiOutlineEye, HiPlus } from "react-icons/hi";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Link,
  Select,
} from "components/common/basic";
import { Banner } from "components/common/composite";
import { FreelanceProfileFragment } from "graphql/fragments";
import {
  useArchiveFreelanceProfileMutation,
  useSetDefaultBankAccountMutation,
  useUnarchiveFreelanceProfileMutation,
} from "graphql/mutations";
import { FreelanceTypeEnum } from "graphql/types";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { TBackgroundColor } from "tw-generated";
import { useRoutePaths } from "utils";
import { firma, firmaDark, lonn, lonnDark } from "utils/brand";
import { tw } from "utils/tw";

import flag from "assets/symbols/flag_no_m.svg";

export default (profile: FreelanceProfileFragment): JSX.Element => {
  const { setDefaultBankAccount } = useSetDefaultBankAccountMutation();
  const { archiveFreelanceProfile } = useArchiveFreelanceProfileMutation();
  const { unarchiveFreelanceProfile } = useUnarchiveFreelanceProfileMutation();

  const { t } = useTranslation("account");
  const getRoutePath = useRoutePaths();
  const { openPopup } = useContext(PopupContext);

  const isOrg = profile.freelanceType === FreelanceTypeEnum.Organization;
  const name = isOrg
    ? `${profile.organizationName} (${profile.organizationNumber})`
    : `${profile.firstName} ${profile.lastName}`;
  const bankAccounts = profile.bankAccounts.filter(
    ({ deletedAt }) => !deletedAt
  );

  const openAddBankAccountPopup = () =>
    openPopup({
      id: "AddBankAccount",
      props: {
        freelanceProfileId: profile.id,
      },
    });

  const openViewBankAccountPopup = () =>
    profile.defaultBankAccount &&
    openPopup({
      id: "ViewBankAccount",
      props: {
        bankAccount: profile.defaultBankAccount,
      },
    });

  const isArchived = Boolean(profile.archivedAt);
  const archiveButtonStyles = tw(
    "underline",
    "text-deepBlue-900",
    "hover:text-deepBlue-700"
  );

  return (
    <Card header={getHeader(isArchived, isOrg)}>
      <CardBody>
        <div className={tw("space-y-1")}>
          <p className={tw("font-bold", "text-gray-900")}>{name}</p>

          <p>
            {isOrg
              ? t("payoutProfiles.card_organization.heading", "Organisation")
              : t("payoutProfiles.card_individual.heading", "Individual")}
          </p>
        </div>

        <div className={tw("flex", "space-x-3")}>
          {isArchived ? (
            <Button
              id="payout_profile_card-edit_profile"
              variant="tertiary"
              size="sm"
              disabled
            >
              {t("payoutProfiles.card.edit", "Edit")}
            </Button>
          ) : (
            <Link
              id="payout_profile_card-edit_profile"
              unstyled
              to={getRoutePath({
                module: "invoicemore",
                routeName: "FREELANCE_PROFILE_WIZARD",
                arg1: profile.id,
              })}
            >
              <Button
                id="payout_profile_card-edit_profile"
                variant="tertiary"
                size="sm"
              >
                {t("payoutProfiles.card.edit", "Edit")}
              </Button>
            </Link>
          )}

          <Link
            id="payout_profile_card-see_agreement"
            unstyled
            isExternal
            to={
              profile.freelanceType === FreelanceTypeEnum.Organization
                ? t(
                    "payoutProfiles.card_organization.linkToAgreement",
                    "https://core.payoutpartner.com/agreement_organization.pdf"
                  )
                : t(
                    "payoutProfiles.card_individual.linkToAgreement",
                    "https://core.payoutpartner.com/agreement_individual.pdf"
                  )
            }
          >
            <Button
              id="payout_profile_card-see_agreement"
              variant="tertiary"
              size="sm"
            >
              {t("payoutProfiles.card.seeAgreement", "See freelance agreement")}
            </Button>
          </Link>
        </div>

        <>
          {!bankAccounts.length ? (
            <Banner
              title={t(
                "payoutProfiles.card.noBankAccount.text",
                "Attach a Bank account to this profile!"
              )}
              actions={[
                {
                  id: "payout_profile_card-add_account",
                  label: t(
                    "payoutProfiles.card.noBankAccount.addAccount",
                    "Add account"
                  ),
                  onClick: openAddBankAccountPopup,
                },
              ]}
            />
          ) : (
            <div className={tw("w-full", "flex", "flex-nowrap", "space-x-3")}>
              <Select
                key={profile.defaultBankAccount?.id}
                className={tw("flex-1")} // w-full pushes buttons out of card
                id="defaultBankAccount"
                name="defaultBankAccount"
                label={t("defaultBankAccount.label", "Default bank account")}
                defaultValue={profile.defaultBankAccount?.id}
                options={bankAccounts.map(
                  ({ id, accountNumber, currency }) => ({
                    key: id,
                    value: id,
                    label: `${accountNumber} (${currency})`,
                  })
                )}
                onChange={(event) => setDefaultBankAccount(event.target.value)}
                disabled={isArchived}
              />

              <div className={tw("self-end")}>
                <Button
                  id="payout_profile_card-open_view_bank_account_popup"
                  size="sm"
                  onClick={openViewBankAccountPopup}
                  variant="tertiary"
                >
                  <HiOutlineEye size={24} />
                </Button>
              </div>

              {!isArchived && (
                <div className={tw("self-end")}>
                  <Button
                    id="payout_profile_card-open_add_bank_account_popup"
                    size="sm"
                    onClick={openAddBankAccountPopup}
                    variant="tertiary"
                  >
                    <HiPlus size={24} />
                  </Button>
                </div>
              )}
            </div>
          )}
        </>

        {isOrg &&
          (isArchived ? (
            <button
              className={archiveButtonStyles}
              onClick={() => unarchiveFreelanceProfile(profile.id)}
            >
              {t(
                "payoutProfiles.card.button.unarchive",
                "Activate payout method again"
              )}
            </button>
          ) : (
            <button
              className={archiveButtonStyles}
              onClick={() => archiveFreelanceProfile(profile.id)}
            >
              {t("payoutProfiles.card.button.archive", "Archive payout method")}
            </button>
          ))}
      </CardBody>
    </Card>
  );
};

const getHeader = (isArchived: boolean, isOrg: boolean) => {
  const headerColor: TBackgroundColor = isArchived
    ? "bg-gray-100"
    : isOrg
      ? "bg-deepBlue-900"
      : "bg-blue-900";

  const image = {
    src: (() => {
      switch (true) {
        case isArchived && isOrg:
          return firmaDark;

        case isArchived && !isOrg:
          return lonnDark;

        case !isArchived && isOrg:
          return firma;

        case !isArchived && !isOrg:
          return lonn;

        default:
          return "";
      }
    })(),
    alt: isOrg ? "Firma" : "Lønn",
  };

  return (
    <CardHeader className={tw(headerColor, "flex", "justify-between")}>
      <img className={tw("h-9")} src={image.src} alt={image.alt} />
      <img src={flag} alt="norway flag" />
    </CardHeader>
  );
};
