import { gql } from "@apollo/client";

import { NoticeID } from "components/common/composite/HeadingWithNotice";
import { Account, Counts, Features, UiFlags, User } from "graphql/types";

import { ACCOUNT_THEME_FRAGMENT, AccountThemeFragment } from "./accountTheme";
import { ADDRESS_FRAGMENT, AddressFragment } from "./address";
import { ATTACHMENT_FRAGMENT, AttachmentFragment } from "./attachment";
import {
  DEPARTMENT_MEMBERSHIP_FRAGMENT,
  DepartmentMembershipFragment,
} from "./departmentMembership";
import { IDENTITY_FRAGMENT, IdentityFragment } from "./identity";

export const ACCOUNT_FRAGMENT = gql`
  fragment AccountFragment on Account {
    id
    slug
    identities {
      ...IdentityFragment
    }
    defaultIdentity {
      ...IdentityFragment
    }
    accountType
    industrySector
    email
    name
    description
    theme {
      ...AccountThemeFragment
    }
    logo {
      ...AttachmentFragment
    }
    symbol {
      ...AttachmentFragment
    }
    symbolDisplayPath
    hasFreelanceProfiles
    phoneNumber
    language
    address {
      ...AddressFragment
    }
    uiFlags {
      shownIds
    }
    features {
      invoices
      payouts
      workflows
    }
    organizationName
    organizationNumber
    pricePlan
    nonProfit
    primaryUser {
      id
      firstName
      lastName
      confirmed
      email
    }
    role
    departmentMemberships {
      ...DepartmentMembershipFragment
    }
    tag
    canManage
    canManageDepartments
    policyDocuments {
      ...AttachmentFragment
    }
    counts {
      departments
      members
    }
    deletionScheduledOn
  }
  ${IDENTITY_FRAGMENT}
  ${ATTACHMENT_FRAGMENT}
  ${ACCOUNT_THEME_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${DEPARTMENT_MEMBERSHIP_FRAGMENT}
`;

interface AccountUiFlags extends Pick<UiFlags, "shownIds"> {
  shownIds: NoticeID[];
}

export interface AccountFragment
  extends Pick<
    Account,
    | "id"
    | "slug"
    | "accountType"
    | "industrySector"
    | "email"
    | "name"
    | "description"
    | "hasFreelanceProfiles"
    | "phoneNumber"
    | "language"
    | "symbolDisplayPath"
    | "organizationName"
    | "organizationNumber"
    | "pricePlan"
    | "nonProfit"
    | "primary"
    | "role"
    | "tag"
    | "canManage"
    | "canManageDepartments"
    | "deletionScheduledOn"
  > {
  identities?: IdentityFragment[];
  defaultIdentity?: IdentityFragment;
  symbol?: AttachmentFragment;
  logo?: AttachmentFragment;
  theme: AccountThemeFragment;
  address?: AddressFragment;
  features: Pick<Features, "invoices" | "payouts" | "workflows">;
  uiFlags: AccountUiFlags;
  primaryUser?: Pick<
    User,
    "id" | "email" | "firstName" | "lastName" | "confirmed"
  >;
  departmentMemberships: DepartmentMembershipFragment[];
  policyDocuments: AttachmentFragment[];
  counts?: Pick<Counts, "departments" | "members">;
}
