import { useContext, useEffect, useRef } from "react";
import { HiOutlineDocument, HiPlus } from "react-icons/hi";
import { useLocation } from "react-router-dom";

import { Button } from "components/common/basic";
import { useMenu } from "hooks";
import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";
import { useRoutePaths } from "utils";
import { tw } from "utils/tw";

import { HeaderTemplate, MenuList } from "./common";
import useTopMenuItems from "./common/useTopMenuItems";

export default (): JSX.Element => {
  const createMenuRef = useRef<HTMLDivElement>(null);
  const [isShowingCreateMenu, setIsShowingCreateMenu] = useMenu(createMenuRef);
  const toggleCreateMenu = () => setIsShowingCreateMenu(!isShowingCreateMenu);

  const { session } = useContext(AuthContext);
  const { t } = useTranslation("common");

  const { pathname } = useLocation();
  const getRoutePath = useRoutePaths();

  const topMenuItems = useTopMenuItems({ session });

  useEffect(() => {
    setIsShowingCreateMenu(false);
  }, [pathname]);

  const createMenuStyles = tw(
    "origin-top-right",
    "absolute",
    "right-0",
    "mt-2",
    "w-56",
    "rounded-md",
    "shadow-lg",
    "bg-white",
    "ring-1",
    "ring-gray-900",
    "ring-opacity-5",
    "focus:outline-none"
  );

  return (
    <>
      <HeaderTemplate
        left={<></>}
        right={
          <>
            <div className={tw("relative")} ref={createMenuRef}>
              {Boolean(session?.account.features.invoices) && (
                <Button
                  id="app_header-create"
                  size="md"
                  aria-label="creation menu"
                  aria-expanded={isShowingCreateMenu}
                  onClick={toggleCreateMenu}
                  TrailingIcon={HiPlus}
                >
                  {t("header_loggedIn.button.create", "Create")}
                </Button>
              )}

              {isShowingCreateMenu && (
                <>
                  <nav className={createMenuStyles}>
                    <MenuList
                      menuItems={[
                        {
                          id: "app_header-create_one_invoice" as const,
                          Icon: HiOutlineDocument,
                          label: t(
                            "header_loggedIn.link.createOneInvoice",
                            "One invoice"
                          ),
                          to: getRoutePath({
                            module: "invoicemore",
                            routeName: "INVOICE_CREATION",
                          }),
                        },
                        // {
                        //   id: "app_header-create_multiple_invoices" as const,
                        //   Icon: HiOutlineDocumentDuplicate,
                        //   label: t(
                        //     "header_loggedIn.link.createMultipleInvoices",
                        //     "Multiple invoices"
                        //   ),
                        //   to: getRoutePath("INVOICE_IMPORT"),
                        // },
                      ]}
                    />
                  </nav>
                </>
              )}
            </div>
          </>
        }
        menuList={<MenuList menuItems={topMenuItems} />}
        showMenuToggle
      />
    </>
  );
};
