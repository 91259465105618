import React from "react";

import { TTailwindString, tw } from "utils/tw";

import Icon, { IconType } from "./Icon/Icon";
import { ActionID } from "./types";

export interface IconButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  id: ActionID;
  icon: IconType;
  isFilled?: boolean;
  className?: TTailwindString;
}

export default React.forwardRef(
  (
    {
      type = "button",
      icon,
      isFilled = false,
      className,
      ...props
    }: React.PropsWithChildren<IconButtonProps>,
    ref: React.ForwardedRef<HTMLButtonElement>
  ): JSX.Element => {
    const buttonStyles = tw(
      "p-1.5",
      "rounded-full",
      "cursor-pointer",
      "hover:bg-silver",
      "focus:bg-silver",
      "focus:outline-none",
      "focus:ring-2",
      "focus:ring-gray-900",
      className
    );

    const isClickable = props.onClick || type !== "button";
    const tabIndex = props.tabIndex ?? (!isClickable ? -1 : undefined);
    const iconProps = { icon, size: 20, color: "text-deepBlue-900" as const };

    if (!isClickable)
      return (
        <div
          {...(props as React.ButtonHTMLAttributes<HTMLDivElement>)}
          className={buttonStyles}
          tabIndex={tabIndex}
        >
          <Icon {...iconProps} />
        </div>
      );

    return (
      <button
        {...props}
        type={type}
        className={buttonStyles}
        tabIndex={tabIndex}
        ref={ref}
      >
        <Icon {...iconProps} isFilled={isFilled} />
      </button>
    );
  }
);
