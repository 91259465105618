import { useContext } from "react";
import {
  HiOutlineClipboardList,
  HiOutlinePencil,
  HiOutlineQuestionMarkCircle,
  HiOutlineTrash,
} from "react-icons/hi";

import { Badge } from "components/common/basic";
import { Table } from "components/common/composite";
import { AccountFragment } from "graphql/fragments";
import {
  useRemoveAccountMemberMutation,
  useSetAccountOwnerMutation,
} from "graphql/mutations";
import { DepartmentRoleEnum, TeamRoleEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { getNameFromAccount } from "utils";
import { tw } from "utils/tw";

import DepartmentItem from "../DepartmentItem";

interface Props {
  members: AccountFragment[];
  footer?: JSX.Element;
}

export default ({ members, footer }: Props): JSX.Element | null => {
  const { removeAccountMember } = useRemoveAccountMemberMutation();
  const { setAccountOwner } = useSetAccountOwnerMutation();

  const { t } = useTranslation("account");
  const { session } = useContext(AuthContext);
  const { openPopup } = useContext(PopupContext);

  if (!session) return null;

  const columns = [
    {
      title: t(
        "accountSettings_org.team.members.table.heading.person",
        "Person"
      ),
      width: "w-48" as const,
      cellID: "person",
      showTotal: false,
    },
    {
      title: t(
        "accountSettings_org.team.members.table.heading.departments",
        "Department and access"
      ),
      width: "w-48" as const,
      cellID: "departments",
      showTotal: false,
    },

    {
      title: t(
        "accountSettings_org.team.members.table.heading.status",
        "Status"
      ),
      width: "w-28" as const,
      cellID: "status",
      showTotal: false,
    },
  ];

  const rows = members.map((member) => {
    const hasOwnerAccess = session.role === TeamRoleEnum.Owner;
    const hasAdminAccess = session.role === TeamRoleEnum.Admin;

    const isOwner = member.role === TeamRoleEnum.Owner;
    const isAdmin = member.role === TeamRoleEnum.Admin;

    const actions = !member.canManageDepartments
      ? []
      : [
          {
            id: "membersTable-edit_member" as const,
            Icon: HiOutlinePencil,
            label: t(
              "accountSettings_org.team.members.table.action.editMember.label",
              "Edit member"
            ),
            onClick: () =>
              openPopup({
                id: "TeamMember",
                props: { account: member },
              }),
          },
          ...(hasOwnerAccess && !isOwner
            ? [
                {
                  id: "membersTable-set_primary" as const,
                  Icon: HiOutlineClipboardList,
                  label: t(
                    "accountSettings_org.team.members.table.action.setAccountOwner.label",
                    "Make owner"
                  ),
                  onClick: () =>
                    openPopup({
                      id: "ConfirmAction",
                      props: {
                        onConfirmation: () =>
                          setAccountOwner({ memberId: member.id }),
                        confirmationLabel: t(
                          "accountSettings_org.team.members.makePrimaryConfirmation.label",
                          "Make admin"
                        ),
                        confirmationHeading: t(
                          "accountSettings_org.team.members.makePrimaryConfirmation.heading",
                          "Are you sure you want to transfer admin rights to this member?"
                        ),
                        confirmationBody: t(
                          "accountSettings_org.team.members.makePrimaryConfirmation.body",
                          "This action is not reversible"
                        ),
                      },
                    }),
                },
              ]
            : []),
          ...((hasOwnerAccess || hasAdminAccess) && !isOwner
            ? [
                {
                  id: "membersTable-remove_member" as const,
                  Icon: HiOutlineTrash,
                  label: t(
                    "accountSettings_org.team.members.table.action.removeMember.label",
                    "Remove member"
                  ),
                  isDestructive: true,
                  onClick: () =>
                    openPopup({
                      id: "ConfirmAction",
                      props: {
                        onConfirmation: () =>
                          removeAccountMember({ memberId: member.id }),
                        confirmationLabel: t(
                          "accountSettings_org.team.members.removeMemberConfirmation.label",
                          "Remove member"
                        ),
                        confirmationHeading: t(
                          "accountSettings_org.team.members.removeMemberConfirmation.heading",
                          "Are you sure you want to remove this member from the team?"
                        ),
                        confirmationBody: t(
                          "accountSettings_org.team.members.removeMemberConfirmation.body",
                          "This action is not reversible"
                        ),
                      },
                    }),
                },
              ]
            : []),
          {
            id: "membersTable-go_to_help" as const,
            Icon: HiOutlineQuestionMarkCircle,
            label: t(
              "accountSettings_org.team.members.table.action.goToHelp.label",
              "Help"
            ),
            to: t(
              "accountSettings_org.team.members.table.action.goToHelp.link",
              "https://en.support.payoutpartner.com"
            ),
            isExternal: true,
          },
        ];

    const memberName = getNameFromAccount(member);

    const cells = {
      person: (
        <div className={tw("flex", "space-x-1")}>
          {member.symbolDisplayPath && (
            <span className={tw("pt-1", "shrink-0")}>
              <img
                className={tw("rounded-full", "w-6", "h-6", "object-cover")}
                src={`${process.env.REACT_APP_API_URL}${member.symbolDisplayPath}`}
                alt="Member avatar"
              />
            </span>
          )}

          <div className={tw("w-full", "overflow-hidden")}>
            <p
              className={tw(
                "text-xs",
                "text-deepBlue-900",
                "font-semibold",
                "break-words",
                "uppercase"
              )}
            >
              {memberName}
              {(() => {
                switch (member.role) {
                  case TeamRoleEnum.Owner:
                    return (
                      <span className={tw("text-success")}>
                        {" "}
                        {t(
                          "accountSettings_org.team.members.table.memberRole.owner",
                          "Owner"
                        )}
                      </span>
                    );

                  case TeamRoleEnum.Admin:
                    return (
                      <span className={tw("text-success")}>
                        {" "}
                        {t(
                          "accountSettings_org.team.members.table.memberRole.admin",
                          "Admin"
                        )}
                      </span>
                    );

                  default:
                    return null;
                }
              })()}
            </p>

            <p className={tw("text-xs", "text-deepBlue-500", "truncate")}>
              {member.email}
            </p>
          </div>
        </div>
      ),
      departments:
        isOwner || isAdmin ? (
          <p>
            {t("accountSettings_org.team.members.table.role.fullAccess", "All")}
          </p>
        ) : (
          <ul className={tw("self-start", "w-full", "space-y-1")}>
            {member.departmentMemberships.map((membership) => {
              const roleString =
                membership.role === DepartmentRoleEnum.Manager
                  ? t(
                      "accountSettings_org.team.members.table.role.manager",
                      "Manager"
                    )
                  : t(
                      "accountSettings_org.team.members.table.role.member",
                      "Member"
                    );

              return (
                <DepartmentItem
                  key={membership.id}
                  id={membership.id}
                  name={`${roleString} - ${membership.department.name}`}
                  crc={membership.criminalRecordAccess}
                  identity={membership.identityAccess}
                />
              );
            })}
          </ul>
        ),
      status: (
        <Badge
          variant={
            member.primaryUser?.confirmed ? "success" : "default_outlined"
          }
          text={
            member.primaryUser?.confirmed
              ? t(
                  "accountSettings_org.team.members.table.status.active",
                  "Active"
                )
              : t(
                  "accountSettings_org.team.members.table.status.invited",
                  "Invited"
                )
          }
        />
      ),
    };

    return {
      id: member.email,
      cells,
      actions,
    };
  });

  return (
    <Table
      columns={columns}
      rows={rows}
      footer={footer}
      actionsLabel={t(
        "accountSettings_org.team.members.table.actions.label",
        "Actions"
      )}
    />
  );
};
