import { tw } from "utils/tw";

interface Props {
  header: string;
}

export default ({
  header,
  children,
}: React.PropsWithChildren<Props>): JSX.Element => (
  <div
    className={tw(
      "text-deepBlue-900",
      "bg-white",
      "py-12",
      "px-6",
      "md:px-20",
      "w-full",
      "max-w-lg",
      "rounded-lg",
      "shadow-xl",
      "space-y-6"
    )}
  >
    <h1 className={tw("text-2xl", "font-extrabold")}>{header}</h1>

    {children}
  </div>
);
