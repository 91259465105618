import { RouteProps } from "react-router-dom";

import {
  AccountInfo,
  AuthSettings,
  Dashboard,
  PageSelection,
  PaymentPlan,
  PayoutSettings,
  PolicyDocuments,
  ScheduledDeletion,
  Team,
} from "components/account/pages";
import { NameSpace } from "translations/shared";
import { getRoutePath } from "utils";

export default (): {
  ns: NameSpace;
  routes: RouteProps[];
  loggedInRoutes: RouteProps[];
  loggedOutRoutes: RouteProps[];
} => {
  // prettier-ignore
  const routes: RouteProps[] = [];

  // prettier-ignore
  const loggedInRoutes: RouteProps[] = [
    { path: getRoutePath("account","DASHBOARD"), element: <Dashboard /> },
    
    { path: getRoutePath("account","SCHEDULED_DELETION"), element: <ScheduledDeletion /> },

    { path: getRoutePath("account","SETTINGS"), element: <PageSelection /> },
    { path: getRoutePath("account","SETTINGS_INFO"), element: <AccountInfo /> },
    { path: getRoutePath("account","SETTINGS_AUTH"), element: <AuthSettings /> },
    { path: getRoutePath("account","SETTINGS_PLAN"), element: <PaymentPlan /> },
    { path: getRoutePath("account","SETTINGS_TEAM"), element: <Team /> },
    { path: getRoutePath("account","SETTINGS_TEAM_MEMBERS"), element: <Team /> },
    { path: getRoutePath("account","SETTINGS_TEAM_DEPARTMENTS"), element: <Team /> },
    { path: getRoutePath("account","SETTINGS_TEAM_ROLES"), element: <Team /> },
    { path: getRoutePath("account","SETTINGS_PAYOUT"), element: <PayoutSettings /> },
    { path: getRoutePath("account","SETTINGS_POLICY_DOCUMENTS"), element: <PolicyDocuments /> },
  ];

  // prettier-ignore
  const loggedOutRoutes: RouteProps[] = [];

  return { ns: "account", routes, loggedInRoutes, loggedOutRoutes };
};
