import { useContext } from "react";
import { HiOutlineKey } from "react-icons/hi";

import { Button, Divider } from "components/common/basic";
import { HeadingWithNotice } from "components/common/composite";
import { Page } from "components/common/wrappers";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import PasswordSection from "./PasswordSection";

export default (): JSX.Element | null => {
  const { t } = useTranslation("account");
  const { session } = useContext(AuthContext);
  const { openPopup } = useContext(PopupContext);

  if (!session) return null;

  return (
    <Page
      title={t(
        "accountSettings_ind.authSettings.pageTitle",
        "Account settings"
      )}
      className={tw("gap-8")}
    >
      <div className={tw("space-y-4")}>
        {/* <AccountCrumbs
          currentPage={t(
            "accountSettings_ind.authSettings.breadcrumb",
            "Login & security"
          )}
        /> */}

        <HeadingWithNotice
          heading={t(
            "accountSettings_ind.authSettings.header",
            "Login & security"
          )}
          LeadingIcon={HiOutlineKey}
          notice={{
            id: "indAccountSettings_authSettings-headerNotice",
            heading: t(
              "accountSettings_ind.authSettings.noticeHeader",
              "Login & security"
            ),
            body: t(
              "accountSettings_ind.authSettings.noticeDescription",
              "Update your password and secure your account."
            ),
          }}
        />
      </div>

      <div
        className={tw(
          "max-w-screen-sm",
          "flex",
          "flex-col",
          "gap-8",
          "lg:flex-row",
          "lg:gap-20"
        )}
      >
        <div className={tw("flex", "flex-col", "gap-7", "w-full")}>
          <PasswordSection />

          <Divider />

          <div className={tw("space-y-6")}>
            <div className={tw("space-y-2")}>
              <h2 className={tw("text-lg", "font-bold")}>
                {t(
                  "accountSettings_ind.authSettings.accountDeletion.header",
                  "Delete account"
                )}
              </h2>

              <p className={tw("text-sm", "text-deepBlue-500")}>
                {t(
                  "accountSettings_ind.authSettings.accountDeletion.helpText",
                  "Deleting your private account means that all information will be deleted. This action can't be undone."
                )}
              </p>
            </div>

            <Button
              id="accountSettings-delete_account"
              variant="tertiary"
              size="sm"
              onClick={() => openPopup({ id: "DeleteAccount" })}
            >
              {t(
                "accountSettings_ind.authSettings.accountDeletion.button.label",
                "Delete account"
              )}
            </Button>
          </div>

          <Divider />
        </div>
      </div>
    </Page>
  );
};
