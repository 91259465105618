import { useContext } from "react";
import { HiOutlineDocumentText } from "react-icons/hi";

import { Divider } from "components/common/basic";
import {
  AttachmentUpload,
  HeadingWithNotice,
} from "components/common/composite";
import { Page } from "components/common/wrappers";
import {
  useCreatePolicyDocumentMutation,
  useDestroyPolicyDocumentMutation,
} from "graphql/mutations";
import { TeamRoleEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import FileUploadIllustration from "assets/illustrations/dragAndDrop/bankStatement.svg";

export default (): JSX.Element | null => {
  const { session } = useContext(AuthContext);
  const { t } = useTranslation("account");

  const { createPolicyDocument } = useCreatePolicyDocumentMutation();
  const { destroyPolicyDocument } = useDestroyPolicyDocumentMutation();

  const hasEditAccess = session?.role
    ? [TeamRoleEnum.Owner, TeamRoleEnum.Admin].includes(session.role)
    : false;

  return (
    <Page
      title={t(
        "accountSettings_org.policyDocuments.pageTitle",
        "Policy documents"
      )}
      className={tw("space-y-8")}
    >
      <div className={tw("space-y-4")}>
        {/* <AccountCrumbs
          currentPage={t(
            "accountSettings_org.policyDocuments.breadcrumb",
            "Policy documents"
          )}
        /> */}

        <HeadingWithNotice
          heading={t(
            "accountSettings_org.policyDocuments.header",
            "Policy documents"
          )}
          LeadingIcon={HiOutlineDocumentText}
          notice={{
            id: "orgAccountSettings_policyDocuments-headerNotice",
            heading: t(
              "accountSettings_org.policyDocuments.noticeHeader",
              "Policy documents"
            ),
            body: t(
              "accountSettings_org.policyDocuments.noticeDescription",
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
            ),
          }}
        />

        <Divider />
      </div>

      <div
        className={tw("flex", "flex-col", "space-y-7", "w-full", "max-w-xl")}
      >
        <div className={tw("space-y-2")}>
          <h2 className={tw("text-deepBlue-900", "font-bold")}>
            {hasEditAccess
              ? t(
                  "accountSettings_org.policyDocuments.description.heading",
                  "Upload your default policy documents"
                )
              : t(
                  "accountSettings_org.policyDocuments_member.description.heading",
                  "View your default policy documents"
                )}
          </h2>

          <p className={tw("text-deepBlue-500")}>
            {hasEditAccess
              ? t(
                  "accountSettings_org.policyDocuments.description.body",
                  "Upload your policy documents here, and we will include them in all checks created under your account. You can choose to manually remove or change the policy documents while creating checks."
                )
              : t(
                  "accountSettings_org.policyDocuments_member.description.body",
                  "Any default policy documents for your account will be listed here, and we will include them in all checks created under your account. You can choose to manually remove or change the policy documents while creating checks."
                )}
          </p>
        </div>

        {session && (
          <AttachmentUpload
            files={session.account.policyDocuments}
            createFile={({ file }) => createPolicyDocument(file)}
            destroyFile={(id) => destroyPolicyDocument(id as string)}
            illustration={{
              src: FileUploadIllustration,
              alt: t(
                "accountSettings_org.policyDocuments.uploadFiles.illustration.alt",
                "File attachment"
              ),
            }}
            validFileTypes={[
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ]}
            fileRequirementsText={t(
              "accountSettings_org.policyDocuments.uploadFiles.validFileTypes",
              "PDF, word-documents. File size up to 10MB"
            )}
            allowMultiple
            hasEditAccess={hasEditAccess}
          />
        )}
      </div>
    </Page>
  );
};
