import { useContext } from "react";
import { useLocation } from "react-router-dom";

import { Button, Link } from "components/common/basic";
import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";
import { RoutesByModule, getPath, useRoutePaths } from "utils";
import { symbol } from "utils/brand";
import { tw } from "utils/tw";

import { HeaderTemplate } from "./common";

export default (): JSX.Element => {
  const { isLoggedIn, logout } = useContext(AuthContext);
  const { t } = useTranslation("common");

  const { pathname } = useLocation();
  const getRoutePath = useRoutePaths();
  const getLoginPath = (routeName: RoutesByModule["login"]) =>
    getRoutePath({ module: "login", routeName });

  const isLoginPage =
    pathname.includes(getPath("login", "LOGIN").path) ||
    pathname.includes(getPath("login", "MAGIC_LINK_LOGIN").path);
  const isSignupPage = pathname.includes(
    getPath("login", "SIGNUP_PERSON").path
  );

  const getLoginButton = () => (
    <Link id="onboarding_header-log_in" to={getLoginPath("LOGIN")} unstyled>
      <Button id="onboarding_header-log_in" size="md" variant="secondary">
        {t("header_onboarding.link.logIn", "Log in")}
      </Button>
    </Link>
  );

  const getSignupButton = (variant: "secondary" | "primary" = "secondary") => (
    <Link
      id="onboarding_header-sign_up"
      to={getLoginPath("SIGNUP_PERSON")}
      unstyled
    >
      <Button id="onboarding_header-sign_up" size="md" variant={variant}>
        {t("header_onboarding.link.signUp", "Sign up")}
      </Button>
    </Link>
  );

  return (
    <>
      <HeaderTemplate
        left={
          <Link
            id="onboarding_header-go_to_homepage"
            to={t("links.homepage", "https://payoutpartner.com/en")}
            isExternal
            unstyled
          >
            <img src={symbol} alt="Logo symbol" className={tw("w-8", "h-8")} />
          </Link>
        }
        right={
          <>
            {isLoggedIn ? (
              <Button
                id="onboarding_header-log_out"
                size="md"
                variant="tertiary"
                onClick={logout}
              >
                {t("header_onboarding.button.logOut", "Log out")}
              </Button>
            ) : isSignupPage ? (
              getLoginButton()
            ) : isLoginPage ? (
              getSignupButton()
            ) : (
              <div className={tw("flex", "items-center", "space-x-6")}>
                {getLoginButton()}

                {getSignupButton("primary")}
              </div>
            )}
          </>
        }
        menuList={[]}
        showMenuToggle={false}
      />
    </>
  );
};
