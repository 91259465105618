import { useContext } from "react";

import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";

import { PopupWrapper } from "./PopupWrapper";

interface Props {
  hasPrevious: boolean;
  onConfirmation: () => void;
  confirmationLabel: string;
  confirmationHeading: string;
  confirmationBody: string | JSX.Element;
  isDestructive?: boolean;
  closeOnConfirmation?: "none" | "one" | "all";
}

export default ({
  onConfirmation,
  confirmationLabel,
  confirmationHeading,
  confirmationBody,
  isDestructive = false,
  closeOnConfirmation = "all",
  ...props
}: Props): JSX.Element => {
  const { closeAllPopups, closeOnePopup } = useContext(PopupContext);
  const { t } = useTranslation("common");

  return (
    <PopupWrapper
      {...props}
      variant="popup"
      title={confirmationHeading}
      subTitle={confirmationBody}
      leadingIcon={isDestructive ? "HiExclamation" : undefined}
      leadingIconColor="text-error"
      footerActions={[
        {
          id: "confirmation_popup-cancel",
          onClick: closeOnePopup,
          variant: "tertiary",
          label: t("popup.confirmAction.cancel", "Cancel"),
        },
        {
          id: "confirmation_popup-confirm",
          onClick: () => {
            onConfirmation();

            if (closeOnConfirmation === "all") closeAllPopups();
            if (closeOnConfirmation === "one") closeOnePopup();
          },
          variant: isDestructive ? "danger" : "primary",
          label: confirmationLabel,
        },
      ]}
    />
  );
};
