import { useContext, useEffect, useState } from "react";
import { HiMenu, HiPlus, HiX } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Divider } from "components/common/basic";
import { AccountFragment } from "graphql/fragments";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";
import { useRoutePaths } from "utils";
import { tw } from "utils/tw";

import { MenuList } from "./common";
import useSideMenuItems from "./common/useSideMenuItems";
import useTopMenuItems from "./common/useTopMenuItems";

export default (): JSX.Element => {
  const [redirectAccount, setRedirectAccount] = useState<AccountFragment>();
  const [isActive, setIsActive] = useState(false);

  const { openPopup } = useContext(PopupContext);
  const { session } = useContext(AuthContext);
  const { t } = useTranslation("common");

  const sideMenuItems = useSideMenuItems({ session });
  const topMenuItems = useTopMenuItems({ session, showLanguageSelect: true });

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const getRoutePath = useRoutePaths();

  // Close menus when changing page
  useEffect(() => {
    setIsActive(false);
  }, [pathname]);

  // Redirect after a new team has been created
  useEffect(() => {
    if (!session || !redirectAccount) return;

    const isAvailable = session.accounts.some(
      ({ id }) => id === redirectAccount.id
    );
    if (!isAvailable) return;

    const redirectToPath = getRoutePath(
      { module: "account", routeName: "DASHBOARD" },
      {
        account: {
          slug: redirectAccount.slug,
          type: redirectAccount.accountType,
        },
      }
    );

    navigate(redirectToPath);
  }, [session?.accounts.length]);

  const tabBarStyles = tw(
    "fixed",
    "z-40",
    "top-0",
    "border-t-2",
    "border-silver",
    "bg-white",
    "w-screen",
    "flex",
    "justify-end"
  );

  const linkBase = tw(
    "flex",
    "justify-center",
    "items-center",
    "gap-2",
    "p-4",
    "text-xs",
    "font-bold"
  );
  const linkStyles = tw(
    linkBase,
    "border-transparent",
    "text-gray-500",
    "hover:border-gray-300",
    "hover:text-gray-700"
  );
  const activeLinkStyles = tw(linkBase, "border-blue-900", "text-blue-900");

  const fullscreenMenuStyles = tw(
    "fixed",
    "z-30",
    "top-0",
    "left-0",
    "bg-white",
    "h-screen",
    "w-screen",
    "pt-16",
    "overflow-auto"
  );

  return (
    <>
      <nav className={tabBarStyles}>
        <button
          aria-label="menu"
          aria-expanded={isActive}
          className={isActive ? activeLinkStyles : linkStyles}
          onClick={() => setIsActive(!isActive)}
        >
          <span
            className={tw(
              "text-sm",
              "font-semibold",
              "text-deepBlue-700",
              "max-w-xs",
              "truncate"
            )}
          >
            {t("layout.appTabBar.button.topMenu", "Menu")}
          </span>

          {isActive ? <HiX size="24px" /> : <HiMenu size="24px" />}
        </button>
      </nav>

      {isActive && (
        <nav className={fullscreenMenuStyles}>
          <MenuList
            menuItems={[...sideMenuItems, "DIVIDER", ...topMenuItems]}
          />

          <div className={tw("w-full", "px-4", "space-y-4")}>
            <Divider />

            <Button
              id="sideMenu-create_team"
              variant="tertiary"
              size="sm"
              fullWidth
              onClick={() =>
                openPopup({
                  id: "AddTeam",
                  props: { onSuccess: setRedirectAccount },
                })
              }
              LeadingIcon={HiPlus}
            >
              {t("layout.appTabBar.sideMenu.button.addTeam", "Add a space")}
            </Button>

            <Divider />
          </div>
        </nav>
      )}
    </>
  );
};
