import ppIcon from "assets/branding/icon.svg";
import ppLogo from "assets/branding/logo.svg";
import pp3rdPartyEN from "assets/branding/pop3rdParty_en.svg";
import pp3rdPartyNO from "assets/branding/pop3rdParty_no.svg";
import ppFirma from "assets/branding/popFirma.svg";
import ppFirmaDark from "assets/branding/popFirma_dark.svg";
import ppLonn from "assets/branding/popLonn.svg";
import ppLonnDark from "assets/branding/popLonn_dark.svg";
import ppSymbol from "assets/branding/symbol.svg";
import ppSymbolDark from "assets/branding/symbol_dark.svg";

export const logo = ppLogo;
export const icon = ppIcon;
export const symbol = ppSymbol;
export const symbolDark = ppSymbolDark;
export const lonn = ppLonn;
export const lonnDark = ppLonnDark;
export const firma = ppFirma;
export const firmaDark = ppFirmaDark;
export const en3rdParty = pp3rdPartyEN;
export const no3rdParty = pp3rdPartyNO;
