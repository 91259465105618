import { useContext, useEffect, useState } from "react";
// eslint-disable-next-line no-restricted-imports
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useParams } from "react-router-dom";

import Cookie from "js-cookie";

import { Spinner } from "components/common/basic";
import { AnalyticsContext } from "providers/Analytics";
import { AuthContext } from "providers/Authentication";
import { PopupContext } from "providers/PopupHandler";
import { IntlContext } from "providers/i18n";
import { NameSpace } from "translations/shared";
import { getParsedJSON } from "utils";
import { Module } from "utils";

interface Props {
  nameSpace: NameSpace;
}

export default ({ nameSpace }: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const { i18n } = useTranslation();
  const { closeAllPopups } = useContext(PopupContext);
  const { currentLocale } = useContext(IntlContext);
  const { session } = useContext(AuthContext);
  const { reportEvent } = useContext(AnalyticsContext);

  const { pathname } = useLocation();
  const { module } = useParams<{ module: Module }>();

  // Load translations for namespace
  useEffect(() => {
    const hasResourceBundle = i18n.hasResourceBundle(currentLocale, nameSpace);

    if (hasResourceBundle) setIsLoading(false);
    else i18n.loadNamespaces(nameSpace).then(() => setIsLoading(false));
  }, [nameSpace]);

  // Store last active account and module
  useEffect(() => {
    if (!session || !module) return;

    const ignoredModules: Module[] = ["test", "common", "login"];
    if (ignoredModules.includes(module)) return;

    const userId = session.user.id;
    const slug = session.account.slug;

    const cookie = Cookie.get("mm-module");
    const parsedCookie = getParsedJSON(cookie);
    const updatedValues = {
      ...(parsedCookie ?? {}),
      [userId]: { slug, module },
    };
    Cookie.set("mm-module", JSON.stringify(updatedValues));
  }, [session, pathname]);

  // On pathname change
  useEffect(() => {
    reportEvent("Displayed Route", { module, pathname });

    window.HubSpotConversations?.widget?.refresh();

    closeAllPopups();
  }, [pathname]);

  if (isLoading) return <Spinner />;

  return <Outlet />;
};
