import { RouteProps } from "react-router-dom";

import {
  CreditNote,
  CreditNoteCreation,
  FreelanceProfileWizard,
  Invoice,
  InvoiceCreation,
  InvoiceDuplication,
  PayoutService,
  PublicInvoice,
} from "components/invoices/pages";
import { NameSpace } from "translations/shared";
import { getRoutePath } from "utils";

export default (): {
  ns: NameSpace;
  routes: RouteProps[];
  loggedInRoutes: RouteProps[];
  loggedOutRoutes: RouteProps[];
} => {
  // prettier-ignore
  const routes: RouteProps[] = [
    { path: getRoutePath("invoicemore", "PUBLIC_INVOICE", ":id"), element: <PublicInvoice /> },
  ];

  // prettier-ignore
  const loggedInRoutes: RouteProps[] = [
    { path: getRoutePath("invoicemore", "PAYOUT_SERVICE"), element: <PayoutService /> },
    { path: getRoutePath("invoicemore", "PAYOUT_SERVICE_INVOICES"), element: <PayoutService /> },
    { path: getRoutePath("invoicemore", "PAYOUT_SERVICE_DRAFTS"), element: <PayoutService /> },
    { path: getRoutePath("invoicemore", "PAYOUT_SERVICE_CREDIT_NOTES"), element: <PayoutService /> },
    { path: getRoutePath("invoicemore", "PAYOUT_SERVICE_BY_CLIENT"), element: <PayoutService /> },

    { path: getRoutePath("invoicemore", "PAYOUT_SERVICE_SALARY_SLIPS"), element: <PayoutService /> },
    { path: getRoutePath("invoicemore", "PAYOUT_SERVICE_SALARY_REPORTS"), element: <PayoutService /> },

    { path: getRoutePath("invoicemore", "INVOICE", ":id"), element: <Invoice /> },
    { path: getRoutePath("invoicemore", "INVOICE_CREATION", ":id"), element: <InvoiceCreation /> },
    { path: getRoutePath("invoicemore", "INVOICE_CREATION"), element: <InvoiceCreation /> },
    { path: getRoutePath("invoicemore", "INVOICE_DUPLICATION", ":id"), element: <InvoiceDuplication /> },

    { path: getRoutePath("invoicemore", "CREDIT_NOTE", ":id"), element: <CreditNote /> },
    { path: getRoutePath("invoicemore", "CREDIT_NOTE_CREATION", ":id"), element: <CreditNoteCreation /> },

    { path: getRoutePath("invoicemore", "FREELANCE_PROFILE_WIZARD", ":id"), element: <FreelanceProfileWizard /> },
    { path: getRoutePath("invoicemore", "FREELANCE_PROFILE_WIZARD"), element: <FreelanceProfileWizard /> },
    
  ];

  // prettier-ignore
  const loggedOutRoutes: RouteProps[] = [];

  return { ns: "invoices", routes, loggedInRoutes, loggedOutRoutes };
};
