import React, { useContext, useEffect } from "react";
// eslint-disable-next-line no-restricted-imports
import { useTranslation } from "react-i18next";

import { useSilentUpdateAccountMutation } from "graphql/mutations";
import { LanguageEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import storage from "utils/storage";

import InitializedI18Next from "./i18n";

export const getIsValidLanguage = (language?: string | null): boolean => {
  if (!language) return false;

  const validOptions = Object.keys(LanguageEnum).map((l) => l.toUpperCase());
  return validOptions.includes(language.toUpperCase());
};

export const getDefaultLanguage = (): LanguageEnum => {
  const locallyStoredLanguage = storage.getLocal("language");
  const environmentDefaultLanguage = process.env.REACT_APP_DEFAULT_LANG;

  if (getIsValidLanguage(locallyStoredLanguage))
    return locallyStoredLanguage as LanguageEnum;

  if (getIsValidLanguage(environmentDefaultLanguage))
    return environmentDefaultLanguage as LanguageEnum;

  return LanguageEnum.En;
};

interface Context {
  currentLocale: LanguageEnum;
  setLocale: (language: LanguageEnum) => void;
}

const defaultLang = getDefaultLanguage();

export const IntlContext = React.createContext<Context>({
  currentLocale: defaultLang,
  setLocale: () => null,
});

interface Props {
  children?: React.ReactNode;
}

const IntlProvider = ({ children }: Props) => {
  const { session } = useContext(AuthContext);
  const { i18n } = useTranslation();

  const { updateAccount } = useSilentUpdateAccountMutation();

  const setLocale = (language: LanguageEnum) => {
    storage.setLocal("language", language);

    i18n.reloadResources().then(() => {
      i18n.changeLanguage(language.toLowerCase());
    });
  };

  const currentLocale = getIsValidLanguage(i18n.language)
    ? (i18n.language.toUpperCase() as LanguageEnum)
    : defaultLang;

  useEffect(() => {
    if (storage.getLocal("language") !== currentLocale)
      storage.setLocal("language", currentLocale);

    if (!session?.user) return;

    if (session.user.language !== currentLocale)
      updateAccount({
        attributes: { language: currentLocale },
        personal: true,
      });
  }, [session?.user.language, currentLocale]);

  return (
    <IntlContext.Provider value={{ currentLocale, setLocale }}>
      {children}
    </IntlContext.Provider>
  );
};

export default ({ children }: Props): JSX.Element => (
  <InitializedI18Next>
    <IntlProvider>{children}</IntlProvider>
  </InitializedI18Next>
);
