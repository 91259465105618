import { useContext } from "react";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { PhoneNumberInput } from "components/common/composite";
import { PopupVariant, PopupWrapper } from "components/common/popups";
import { useUpdateAccountMutation } from "graphql/mutations";
import { useErrorLogger } from "hooks";
import { PopupContext } from "providers/PopupHandler";
import { useTranslation } from "translations";

import { PhoneCountry, getCountries } from "../composite";

interface FormValues {
  phoneCountry: PhoneCountry;
  phoneNumber: string;
}

interface Props {
  hasPrevious: boolean;
  variant: PopupVariant;
  phone?: string;
}

export default (props: Props): JSX.Element => {
  const { updateAccount } = useUpdateAccountMutation();

  const { closeOnePopup } = useContext(PopupContext);
  const { t } = useTranslation("account");

  const validationSchema = Yup.object({
    phoneCountry: Yup.mixed<PhoneCountry>().required(),
    phoneNumber: Yup.string().required(),
  });

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      phoneCountry:
        (props.phone
          ? getCountries().find(
              ({ phonePrefix }) => phonePrefix === props.phone?.split(" ")[0]
            )
          : null) ?? getCountries()[0],
      phoneNumber: props.phone ? props.phone.split(" ")[1] : "",
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);
  const phoneCountry = watch("phoneCountry");

  const onSubmit = ({ phoneCountry, phoneNumber }: FormValues) => {
    const attributes = {
      phoneNumber: `${phoneCountry.phonePrefix} ${phoneNumber}`,
    };
    updateAccount({ attributes }, closeOnePopup);
  };

  const formId = "phoneNumberForm";

  return (
    <PopupWrapper
      {...props}
      nameSpace="account"
      title={t(
        "accountSettings.phoneNumberPopup.heading",
        "Add your phone number"
      )}
      subTitle={t(
        "accountSettings.phoneNumberPopup.description",
        "Add your phone number to your settings"
      )}
      footerActions={[
        {
          id: "accountSettings-add_phone",
          type: "submit",
          form: formId,
          variant: "primary",
          label: t("accountSettings.phoneNumberPopup.button.submit", "Save"),
          disabled: !isValid,
        },
      ]}
    >
      <form onSubmit={handleSubmit(onSubmit)} id={formId}>
        <PhoneNumberInput
          id="phone"
          label={t(
            "accountSettings.phoneNumberPopup.phoneNumber.label",
            "Phone number"
          )}
          selectedCountry={phoneCountry}
          setSelectedCountry={(value) => setValue("phoneCountry", value)}
          registerNumber={register("phoneNumber")}
        />
      </form>
    </PopupWrapper>
  );
};
