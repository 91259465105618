import {
  HiExclamation,
  HiLockClosed,
  HiOutlineCash,
  HiOutlineCollection,
  HiOutlineCurrencyDollar,
  HiOutlineDocument,
  HiOutlineDocumentRemove,
  HiOutlineDocumentReport,
  HiOutlineDocumentText,
  HiOutlineKey,
  HiOutlineOfficeBuilding,
  HiOutlineUserCircle,
  HiOutlineUserGroup,
} from "react-icons/hi";
import { useLocation } from "react-router-dom";

import { SessionFragment } from "graphql/fragments";
import { AccountTypeEnum } from "graphql/types";
import { useTranslation } from "translations";
import { GetRoutePathProps, Module, useRoutePaths } from "utils";
import { symbolDark } from "utils/brand";

import { MenuItem, MenuItemGroup } from "./MenuList/types";

interface Props {
  session?: SessionFragment;
}

export default ({ session }: Props): MenuItem[] => {
  const { t } = useTranslation("common");

  const getRoutePath = useRoutePaths();
  const { pathname } = useLocation();

  if (!session) return [];

  return session.accounts.reduce(
    (
      acc: MenuItem[],
      {
        slug,
        accountType,
        features,
        name,
        email,
        symbolDisplayPath,
        hasFreelanceProfiles,
        primaryUser,
        deletionScheduledOn,
      }
    ) => {
      const isTeam = accountType === AccountTypeEnum.Team;
      const logoImg = symbolDisplayPath
        ? {
            src: `${process.env.REACT_APP_API_URL}${symbolDisplayPath}`,
            alt: "Profile image",
          }
        : undefined;

      const getAccountPath: <T extends Module>(
        route: GetRoutePathProps<T>
      ) => string = (route) =>
        getRoutePath(route, { account: { slug, type: accountType } });

      const pathnameWithAccount = (() => {
        const [prefix, version, locale, _type, _slug, ...rest] =
          pathname.split("/");

        return [
          prefix,
          version,
          locale,
          session.account.accountType.toLowerCase(),
          session.account.slug,
          ...rest,
        ].join("/");
      })();

      const hasInvoicemore =
        features.invoices !== false && hasFreelanceProfiles;
      const hasPayoutmore = features.payouts !== false;

      const scheduledDeletionItems = [
        {
          id: `sideMenu-go_to_settings:${slug}` as const,
          Icon: HiExclamation,
          iconClassName: "text-error",
          isDestructive: true,
          label: t(
            "layout.sideMenu.link.scheduledDeletion",
            "Deletion scheduled"
          ),
          to: getAccountPath({
            module: "account",
            routeName: "SCHEDULED_DELETION",
          }),
          isActive: pathnameWithAccount.includes(
            getAccountPath({
              module: "account",
              routeName: "SCHEDULED_DELETION",
            })
          ),
        },
      ];

      if (isTeam) {
        const hasRecruitmore = features.workflows !== false;

        const partnerGroup: MenuItemGroup = {
          id: `sideMenu-partner_group:${slug}`,
          variant: "sideMenu",
          isOpenByDefault: slug === session.account.slug,
          label: name ?? email,
          image: {
            src: logoImg ? logoImg.src : symbolDark,
            alt: "Partner logo",
          },
          items: deletionScheduledOn
            ? scheduledDeletionItems
            : [
                {
                  id: `sideMenu-go_to_settings:${slug}` as const,
                  Icon: HiOutlineOfficeBuilding,
                  label: t("layout.sideMenu_team.link.orgInfo", "Org info"),
                  to: getAccountPath({
                    module: "account",
                    routeName: "SETTINGS_INFO",
                  }),
                  isActive: pathnameWithAccount.includes(
                    getAccountPath({
                      module: "account",
                      routeName: "SETTINGS_INFO",
                    })
                  ),
                },
                {
                  id: `sideMenu-go_to_settings:${slug}` as const,
                  Icon: HiOutlineUserGroup,
                  label: t("layout.sideMenu_team.link.team", "Team"),
                  to: getAccountPath({
                    module: "account",
                    routeName: "SETTINGS_TEAM",
                  }),
                  isActive: pathnameWithAccount.includes(
                    getAccountPath({
                      module: "account",
                      routeName: "SETTINGS_TEAM",
                    })
                  ),
                },
                {
                  id: `sideMenu-go_to_settings:${slug}` as const,
                  Icon: HiOutlineDocumentText,
                  label: t(
                    "layout.sideMenu_team.link.payoutSettings",
                    "Invoicing service"
                  ),
                  to: getAccountPath({
                    module: "account",
                    routeName: "SETTINGS_PAYOUT",
                  }),
                  isActive: pathnameWithAccount.includes(
                    getAccountPath({
                      module: "account",
                      routeName: "SETTINGS_PAYOUT",
                    })
                  ),
                },

                ...(hasRecruitmore
                  ? [
                      {
                        id: `sideMenu-go_to_workflows:${slug}` as const,
                        Icon: HiOutlineCollection,
                        label: t(
                          "layout.sideMenu_team.link.workflows",
                          "Workflows"
                        ),
                        to: getAccountPath({
                          module: "recruitmore",
                          routeName: "JOBS",
                        }),
                        isActive: pathnameWithAccount.includes(
                          getAccountPath({
                            module: "recruitmore",
                            routeName: "JOBS",
                          })
                        ),
                      },
                    ]
                  : []),
                ...(hasInvoicemore
                  ? [
                      {
                        text: t(
                          "layout.sideMenu_team.subHeading.invoicing",
                          "Invoicing"
                        ),
                        isSubHeading: true,
                      },
                      {
                        id: `sideMenu-go_to_invoices:${slug}` as const,
                        Icon: HiOutlineDocumentText,
                        label: t(
                          "layout.sideMenu_team.link.invoices",
                          "Invoices"
                        ),
                        to: getAccountPath({
                          module: "invoicemore",
                          routeName: "PAYOUT_SERVICE_INVOICES",
                        }),
                        isActive: pathnameWithAccount.includes(
                          getAccountPath({
                            module: "invoicemore",
                            routeName: "PAYOUT_SERVICE_INVOICES",
                          })
                        ),
                      },
                      {
                        id: `sideMenu-go_to_invoiceDrafts:${slug}` as const,
                        Icon: HiOutlineDocument,
                        label: t("layout.sideMenu_team.link.drafts", "Drafts"),
                        to: getAccountPath({
                          module: "invoicemore",
                          routeName: "PAYOUT_SERVICE_DRAFTS",
                        }),
                        isActive: pathnameWithAccount.includes(
                          getAccountPath({
                            module: "invoicemore",
                            routeName: "PAYOUT_SERVICE_DRAFTS",
                          })
                        ),
                      },
                      {
                        id: `sideMenu-go_to_creditNotes:${slug}` as const,
                        Icon: HiOutlineDocumentRemove,
                        label: t(
                          "layout.sideMenu_team.link.creditNotes",
                          "Credit notes"
                        ),
                        to: getAccountPath({
                          module: "invoicemore",
                          routeName: "PAYOUT_SERVICE_CREDIT_NOTES",
                        }),
                        isActive: pathnameWithAccount.includes(
                          getAccountPath({
                            module: "invoicemore",
                            routeName: "PAYOUT_SERVICE_CREDIT_NOTES",
                          })
                        ),
                      },
                      {
                        id: `sideMenu-go_to_clients:${slug}` as const,
                        Icon: HiOutlineUserGroup,
                        label: t(
                          "layout.sideMenu_team.link.clients",
                          "Clients"
                        ),
                        to: getAccountPath({
                          module: "invoicemore",
                          routeName: "PAYOUT_SERVICE_BY_CLIENT",
                        }),
                        isActive: pathnameWithAccount.includes(
                          getAccountPath({
                            module: "invoicemore",
                            routeName: "PAYOUT_SERVICE_BY_CLIENT",
                          })
                        ),
                      },
                    ]
                  : []),
                ...(hasPayoutmore
                  ? [
                      // {
                      //   text: t("layout.sideMenu_team.module.payoutmore", "payoutmore"),
                      //   isSubHeading: true,
                      // },
                      {
                        id: `sideMenu-go_to_payouts:${slug}` as const,
                        Icon: HiOutlineCurrencyDollar,
                        label: t(
                          "layout.sideMenu_team.link.payouts",
                          "Payouts"
                        ),
                        to: getAccountPath({
                          module: "payoutmore",
                          routeName: "PURCHASES",
                        }),
                        isActive: pathnameWithAccount.includes(
                          getAccountPath({
                            module: "payoutmore",
                            routeName: "PURCHASES",
                          })
                        ),
                      },
                    ]
                  : []),
              ],
        };

        return [
          ...acc,
          "DIVIDER",
          {
            text: t("layout.sideMenu_team.groupTitle.freeSpace", "Team"),
          },
          partnerGroup,
        ];
      }

      const workerName =
        name ||
        (primaryUser?.firstName &&
          primaryUser?.lastName &&
          `${primaryUser.firstName} ${primaryUser.lastName}`);

      const workerGroup: MenuItemGroup = {
        id: `sideMenu-worker_group:${slug}`,
        variant: "sideMenu",
        isOpenByDefault: slug === session.account.slug,
        label: workerName ?? email,
        ...(logoImg ? { image: logoImg } : { Icon: HiOutlineUserCircle }),
        items: deletionScheduledOn
          ? scheduledDeletionItems
          : [
              {
                id: `sideMenu-go_to_settings:${slug}` as const,
                Icon: HiOutlineUserCircle,
                label: t(
                  "layout.sideMenu_personal.link.accountInfo",
                  "Account info"
                ),
                to: getAccountPath({
                  module: "account",
                  routeName: "SETTINGS_INFO",
                }),
                isActive: pathnameWithAccount.includes(
                  getAccountPath({
                    module: "account",
                    routeName: "SETTINGS_INFO",
                  })
                ),
              },
              {
                id: `sideMenu-go_to_settings:${slug}` as const,
                Icon: HiOutlineKey,
                label: t(
                  "layout.sideMenu_personal.link.authentication",
                  "Login & security"
                ),
                to: getAccountPath({
                  module: "account",
                  routeName: "SETTINGS_AUTH",
                }),
                isActive: pathnameWithAccount.includes(
                  getAccountPath({
                    module: "account",
                    routeName: "SETTINGS_AUTH",
                  })
                ),
              },
              ...(features.invoices !== false
                ? [
                    {
                      id: `sideMenu-go_to_settings:${slug}` as const,
                      Icon: HiOutlineCurrencyDollar,
                      label: t(
                        "layout.sideMenu_personal.link.payoutSettings",
                        "Payout service"
                      ),
                      to: getAccountPath({
                        module: "account",
                        routeName: "SETTINGS_PAYOUT",
                      }),
                      isActive: pathnameWithAccount.includes(
                        getAccountPath({
                          module: "account",
                          routeName: "SETTINGS_PAYOUT",
                        })
                      ),
                    },
                  ]
                : []),

              ...(hasInvoicemore
                ? [
                    {
                      text: t(
                        "layout.sideMenu_personal.subHeading.invoicing",
                        "Invoicing"
                      ),
                      isSubHeading: true,
                    },
                    {
                      id: `sideMenu-go_to_invoices:${slug}` as const,
                      Icon: HiOutlineDocumentText,
                      label: t(
                        "layout.sideMenu_personal.link.invoices",
                        "Invoices"
                      ),
                      to: getAccountPath({
                        module: "invoicemore",
                        routeName: "PAYOUT_SERVICE_INVOICES",
                      }),
                      isActive: pathnameWithAccount.includes(
                        getAccountPath({
                          module: "invoicemore",
                          routeName: "PAYOUT_SERVICE_INVOICES",
                        })
                      ),
                    },
                    {
                      id: `sideMenu-go_to_invoiceDrafts:${slug}` as const,
                      Icon: HiOutlineDocument,
                      label: t(
                        "layout.sideMenu_personal.link.drafts",
                        "Drafts"
                      ),
                      to: getAccountPath({
                        module: "invoicemore",
                        routeName: "PAYOUT_SERVICE_DRAFTS",
                      }),
                      isActive: pathnameWithAccount.includes(
                        getAccountPath({
                          module: "invoicemore",
                          routeName: "PAYOUT_SERVICE_DRAFTS",
                        })
                      ),
                    },
                    {
                      id: `sideMenu-go_to_creditNotes:${slug}` as const,
                      Icon: HiOutlineDocumentRemove,
                      label: t(
                        "layout.sideMenu_personal.link.creditNotes",
                        "Credit notes"
                      ),
                      to: getAccountPath({
                        module: "invoicemore",
                        routeName: "PAYOUT_SERVICE_CREDIT_NOTES",
                      }),
                      isActive: pathnameWithAccount.includes(
                        getAccountPath({
                          module: "invoicemore",
                          routeName: "PAYOUT_SERVICE_CREDIT_NOTES",
                        })
                      ),
                    },
                    {
                      id: `sideMenu-go_to_clients:${slug}` as const,
                      Icon: HiOutlineUserGroup,
                      label: t(
                        "layout.sideMenu_personal.link.clients",
                        "Clients"
                      ),
                      to: getAccountPath({
                        module: "invoicemore",
                        routeName: "PAYOUT_SERVICE_BY_CLIENT",
                      }),
                      isActive: pathnameWithAccount.includes(
                        getAccountPath({
                          module: "invoicemore",
                          routeName: "PAYOUT_SERVICE_BY_CLIENT",
                        })
                      ),
                    },

                    ...(hasFreelanceProfiles
                      ? [
                          {
                            text: t(
                              "layout.sideMenu_personal.subHeading.salaries",
                              "Salaries"
                            ),
                            isSubHeading: true,
                          },
                          {
                            id: `sideMenu-go_to_salarySlips:${slug}` as const,
                            Icon: HiOutlineCash,
                            label: t(
                              "layout.sideMenu_personal.link.salarySlips",
                              "Salary slips"
                            ),
                            to: getAccountPath({
                              module: "invoicemore",
                              routeName: "PAYOUT_SERVICE_SALARY_SLIPS",
                            }),
                            isActive: pathnameWithAccount.includes(
                              getAccountPath({
                                module: "invoicemore",
                                routeName: "PAYOUT_SERVICE_SALARY_SLIPS",
                              })
                            ),
                          },
                          {
                            id: `sideMenu-go_to_salaryReports:${slug}` as const,
                            Icon: HiOutlineDocumentReport,
                            label: t(
                              "layout.sideMenu_personal.link.salaryReports",
                              "Salary reports"
                            ),
                            to: getAccountPath({
                              module: "invoicemore",
                              routeName: "PAYOUT_SERVICE_SALARY_REPORTS",
                            }),
                            isActive: pathnameWithAccount.includes(
                              getAccountPath({
                                module: "invoicemore",
                                routeName: "PAYOUT_SERVICE_SALARY_REPORTS",
                              })
                            ),
                          },
                        ]
                      : []),
                  ]
                : []),
            ],
      };
      return [
        {
          text: t("layout.sideMenu_personal.groupTitle.private", "Private"),
          Icon: HiLockClosed,
        },
        workerGroup,
        ...acc,
      ];
    },
    []
  );
};
