import { useEffect, useRef } from "react";

import { CheckBox } from "components/common/basic";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import Pagination from "./Pagination";

export interface Props<T> {
  itemKey: string;
  items: T[];
  selection: T[];
  setSelection: (items: T[]) => void;
  page: number;
  pages: number;
  showPagination: boolean;
  showSelectAll: boolean;
  setPage: (page: number) => void;
  renderListItemLabel: (item: T) => JSX.Element;
  onSelect: (items: T[]) => void;
  listHeader?: JSX.Element;
  emptyState?: JSX.Element;
  footer?: JSX.Element;
}

export default <T,>({
  itemKey,
  items,
  selection,
  setSelection,
  page,
  pages,
  showPagination,
  showSelectAll,
  setPage,
  renderListItemLabel,
  onSelect,
  listHeader,
  emptyState,
  footer,
}: Props<T>): JSX.Element => {
  const { t } = useTranslation("common");

  const goBack = () => {
    if (page <= 1) return;

    setPage(page - 1);
  };

  const goNext = () => {
    if (page >= pages) return;

    setPage(page + 1);
  };

  // Submit selection on unmount
  const selectionRef = useRef(selection);
  useEffect(() => {
    selectionRef.current = selection;
  }, [selection]);
  useEffect(() => () => onSelect(selectionRef.current), []);

  const getKeyValueFromItem = (item: T): string | null => {
    const keyFromItem =
      Object.prototype.hasOwnProperty.call(item, itemKey) &&
      (item as { [itemKey: string]: unknown })[itemKey];

    if (typeof keyFromItem === "string") return keyFromItem;

    return null;
  };

  if (emptyState && items.length < 1) return emptyState;

  const getIsInItems = (items: T[]) => (item: T) =>
    items.some(
      (itemInItems) =>
        getKeyValueFromItem(itemInItems) === getKeyValueFromItem(item)
    );
  const hasAllSelected = items.every(getIsInItems(selection));

  return (
    <>
      {listHeader}

      <ul
        className={tw(
          "bg-white",
          "py-1",
          "space-y-1",
          "h-full",
          "overflow-y-auto"
        )}
      >
        {showSelectAll && (
          <li>
            <button
              type="button"
              onClick={
                hasAllSelected
                  ? () =>
                      setSelection([
                        ...selection.filter(
                          (item) => !getIsInItems(items)(item)
                        ),
                      ])
                  : () =>
                      setSelection([
                        ...selection,
                        ...items.filter(
                          (item) => !getIsInItems(selection)(item)
                        ),
                      ])
              }
              className={tw(
                "text-left",
                "w-full",
                "hover:bg-silver",
                "py-1",
                "px-3"
              )}
            >
              <CheckBox
                id="freelance_profile_agreement-go_to_agreement"
                name="checkbox icon"
                checked={hasAllSelected}
                isNonClickable
                label={t("search_multi.selectAll", "Select all")}
              />
            </button>
          </li>
        )}

        {items.map((item, index) => {
          const keyValue = getKeyValueFromItem(item);

          const isSelected =
            Boolean(keyValue) &&
            selection.some(
              (selectedItem) => getKeyValueFromItem(selectedItem) === keyValue
            );

          const removeFromSelection = () =>
            setSelection(
              selection.filter(
                (selectedItem) => getKeyValueFromItem(selectedItem) !== keyValue
              )
            );
          const addToSelection = () => setSelection([...selection, item]);
          const toggleSelected = isSelected
            ? removeFromSelection
            : addToSelection;

          return (
            <li key={keyValue ?? index}>
              <button
                type="button"
                onClick={toggleSelected}
                className={tw(
                  "text-left",
                  "w-full",
                  "hover:bg-silver",
                  "py-1",
                  "px-3"
                )}
              >
                <CheckBox
                  id="freelance_profile_agreement-go_to_agreement"
                  name="checkbox icon"
                  checked={isSelected}
                  isNonClickable
                  label={renderListItemLabel(item)}
                />
              </button>
            </li>
          );
        })}
      </ul>

      <div className={tw("p-4", "space-y-4")}>
        {showPagination && (
          <Pagination
            page={page}
            pages={pages}
            goBack={goBack}
            goNext={goNext}
          />
        )}

        {footer}
      </div>
    </>
  );
};
