import { RouteProps } from "react-router-dom";

import {
  Login,
  MagicLink,
  MagicLinkVerification,
  SignupPerson,
} from "components/login/pages";
import { NameSpace } from "translations/shared";
import { getRoutePath } from "utils";

export default (): {
  ns: NameSpace;
  routes: RouteProps[];
  loggedInRoutes: RouteProps[];
  loggedOutRoutes: RouteProps[];
} => {
  // prettier-ignore
  const routes: RouteProps[] = [
    { path: getRoutePath("login","MAGIC_LINK_VERIFICATION"), element: <MagicLinkVerification /> },
  ];

  // prettier-ignore
  const loggedInRoutes: RouteProps[] = [];

  // prettier-ignore
  const loggedOutRoutes: RouteProps[] = [
    { path: getRoutePath("login","LOGIN"), element: <Login /> },
    { path: getRoutePath("login","SIGNUP_PERSON"), element: <SignupPerson /> },
    { path: getRoutePath("login","MAGIC_LINK_LOGIN"), element: <MagicLink /> },
  ];

  return { ns: "login", routes, loggedInRoutes, loggedOutRoutes };
};
