import { Banner } from "components/common/composite";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

export default (): JSX.Element | null => {
  const { t } = useTranslation("common");

  const isOutdated = typeof Promise.withResolvers === "undefined";

  return (
    <div className={tw("flex", "flex-col", "gap-px")}>
      {isOutdated && (
        <Banner
          rounded={false}
          variant="warning"
          title={t(
            "bannerSection.outdated.title",
            "Looks like you are using an outdated browser"
          )}
          body={t(
            "bannerSection.outdated.body",
            "Manymore functions best on the newest browser versions, and we recommend that you update your browser."
          )}
        />
      )}
    </div>
  );
};
