/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { CreateSessionInput, CreateSessionPayload } from "graphql/types";

const CREATE_SESSION = gql`
  mutation ($input: CreateSessionInput!) {
    createSession(input: $input) {
      token
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload extends Pick<CreateSessionPayload, "token"> {
  errors: ErrorFragment[];
}

export interface CreateSessionMutation {
  createSession: Payload | null;
}

export const useCreateSessionMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler();

  const [mutation, { data, loading }] = useMutation<
    CreateSessionMutation,
    { input: CreateSessionInput }
  >(CREATE_SESSION);

  const createSession = (
    input: CreateSessionInput,
    onSuccess?: (sessionToken: string) => void
  ) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.createSession?.errors ?? [];
        const sessionToken = data?.createSession?.token;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && sessionToken && onSuccess(sessionToken),
          onRetry: () => createSession(input, onSuccess),
          payload: { token: sessionToken },
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createSession,
    session: data?.createSession,
    isLoading: loading,
    errors: data?.createSession?.errors ?? [],
  };
};
