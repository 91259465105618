import { Button, Link } from "components/common/basic";
import { Trans, useTranslation } from "translations";
import { useRoutePaths } from "utils";
import { tw } from "utils/tw";

import WomanSitting from "assets/illustrations/emptyState/womanSitting.svg";

export default (): JSX.Element => {
  const { t } = useTranslation("account");
  const getRoutePath = useRoutePaths();

  return (
    <div
      className={tw(
        "max-w-sm",
        "mx-auto",
        "text-center",
        "flex",
        "flex-col",
        "space-y-10"
      )}
    >
      <img
        className={tw("h-40", "object-cover")}
        src={WomanSitting}
        alt="Woman sitting in chair"
      />

      <div className={tw("space-y-2")}>
        <h2 className={tw("text-lg", "font-extrabold")}>
          {t(
            "payoutProfiles.empty.heading",
            "You have not added any payout profile yet"
          )}
        </h2>

        <p>
          <Trans ns="account" i18nKey="payoutProfiles.empty.paragraph">
            To be able to use Manymore’s payout and invoicing service we need
            you to register at least one payout profile.{" "}
            <Link
              id="payout_profiles__empty_state-go_to_readmore"
              isExternal
              to={t(
                "payoutProfiles.empty.link.readMore",
                "https://en.support.payoutpartner.com"
              )}
            >
              Read more about payout profiles here.
            </Link>
          </Trans>
        </p>
      </div>

      <Link
        id="payout_profiles__empty_state-add_first_profile"
        to={getRoutePath({
          module: "invoicemore",
          routeName: "FREELANCE_PROFILE_WIZARD",
        })}
        unstyled
      >
        <Button id="payout_profiles__empty_state-add_first_profile">
          <Trans
            ns="account"
            i18nKey="payoutProfiles.empty.button.addFreelanceProfile"
          >
            Add your first payout profile now
          </Trans>
        </Button>
      </Link>
    </div>
  );
};
