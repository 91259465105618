import { useState } from "react";

import BankAccounts from "./BankAccounts";
import PayoutProfiles from "./PayoutProfiles";

enum TabEnum {
  "profiles" = "PAYOUT_PROFILES",
  "bank-accounts" = "BANK_ACCOUNTS",
}

export default (): JSX.Element | null => {
  const [currentTab] = useState(TabEnum.profiles);

  switch (currentTab) {
    case TabEnum["profiles"]:
      return <PayoutProfiles />;

    case TabEnum["bank-accounts"]:
      return <BankAccounts />;

    default:
      return null;
  }
};
