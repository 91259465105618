import { useContext } from "react";
import { IconType } from "react-icons";
import {
  HiOutlineCreditCard,
  HiOutlineCurrencyDollar,
  HiOutlineIdentification,
  HiOutlineUserGroup,
} from "react-icons/hi";

import { ActionID } from "components/common/basic";
import { AccountTagEnum, TeamRoleEnum } from "graphql/types";
import { AuthContext } from "providers/Authentication";
import { useTranslation } from "translations";
import { RoutesByModule } from "utils";

interface LinkProps {
  actionId: ActionID;
  routeName: RoutesByModule["account"];
  Icon: IconType;
  label: string;
  helpText: string;
}

export default (): LinkProps[] => {
  const { session } = useContext(AuthContext);
  const { t } = useTranslation("account");

  if (!session) return [];

  const hasOwnerAccess = session.role === TeamRoleEnum.Owner;
  const hasAdminAccess = session.role === TeamRoleEnum.Admin;

  const hasPricePlanAccess =
    (hasOwnerAccess || hasAdminAccess) &&
    session.account.tag === AccountTagEnum.MarchingBand &&
    session.account.pricePlan;

  const links: LinkProps[] = [
    {
      actionId: "accountSettings-go_to_orgInfo",
      routeName: "SETTINGS_INFO",
      Icon: HiOutlineIdentification,
      label: t("accountSettings_org.link.orgInfo.label", "Org info"),
      helpText: t(
        "accountSettings_org.link.orgInfo.helpText",
        "Provide personal details and how we can reach you"
      ),
    },
    {
      actionId: "accountSettings-go_to_team",
      routeName: "SETTINGS_TEAM",
      Icon: HiOutlineUserGroup,
      label: t("accountSettings_org.link.team.label", "Team"),
      helpText: t(
        "accountSettings_org.link.team.helpText",
        "Add internal staff, create teams, set roles and locations"
      ),
    },
    ...(hasPricePlanAccess
      ? [
          {
            actionId: "accountSettings-go_to_paymentPlan" as const,
            routeName: "SETTINGS_PLAN" as const,
            Icon: HiOutlineCreditCard,
            label: t(
              "accountSettings_org.link.paymentPlan.label",
              "Payment plan"
            ),
            helpText: t(
              "accountSettings_org.link.paymentPlan.helpText",
              "Set and adjust your payment plan for your organisation."
            ),
          },
        ]
      : []),

    {
      actionId: "accountSettings-go_to_paymentSettings" as const,
      routeName: "SETTINGS_PAYOUT" as const,
      Icon: HiOutlineCurrencyDollar,
      label: t(
        "accountSettings_org.link.payoutSettings.label",
        "Invoicing service"
      ),
      helpText: t(
        "accountSettings_org.link.payoutSettings.helpText",
        "Set and adjust your payment settings for your organisation."
      ),
    },
  ];

  return links;
};
