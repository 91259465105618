import { IconType } from "react-icons";

import { tw } from "utils/tw";

interface Props {
  label: string;
  disabled?: boolean;
  onClick?: () => void;
  LeadingIcon?: IconType;
}

export default ({
  label,
  disabled,
  onClick,
  LeadingIcon,
}: Props): JSX.Element => {
  const lineStyles = tw("flex-1", "h-px", "bg-gray-300");
  const buttonStyles = tw(
    "flex",
    "items-center",
    "py-2",
    "px-3",
    "gap-2",
    "text-sm",
    "text-gray-700",
    "font-semibold",
    "shadow-sm",
    "border",
    "border-gray-300",
    "rounded-full",
    "bg-white",
    "hover:bg-gray-100",
    "focus:outline-none",
    "focus:ring-2",
    "focus:ring-offset-2",
    "focus:ring-gray-900"
  );

  return (
    <div className={tw("flex", "items-center")}>
      <span className={lineStyles} />

      <button
        type="button"
        onClick={onClick}
        disabled={disabled}
        className={buttonStyles}
      >
        {LeadingIcon && <LeadingIcon size={16} className={tw("shrink-0")} />}

        {label}
      </button>

      <span className={lineStyles} />
    </div>
  );
};
