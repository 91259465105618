import { useContext, useState } from "react";
import { Control, Controller, UseFormRegister, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Button, Divider, Input, Select } from "components/common/basic";
import {
  BankAccountSection,
  ReferencePeopleSection,
  ReferencePeopleSectionOrganization,
} from "components/common/composite";
import {
  FreelanceProfileFragment,
  ReferencePersonFragment,
} from "graphql/fragments";
import {
  useCreateFreelanceProfileMutation,
  useUpdateFreelanceProfileMutation,
} from "graphql/mutations";
import {
  BankAccountAttributes,
  CurrencyEnum,
  FreelanceTypeEnum,
} from "graphql/types";
import { useErrorLogger } from "hooks";
import { AuthContext } from "providers/Authentication";
import { IntlContext } from "providers/i18n";
import { Trans, useTranslation } from "translations";
import {
  bankAccountNumberValidationSchema,
  emailValidationSchema,
  getAlphanumericValueFromString,
  getCountryNameFromCode,
  getNonSanctionedCountries,
  phoneValidationSchema,
  referencePersonToAttributes,
} from "utils";
import { tw } from "utils/tw";

interface FormValues {
  id?: string;
  freelanceType: FreelanceTypeEnum;
  organizationName: string;
  organizationNumber: string;
  address: {
    line1: string;
    line2?: string;
    postalCode: string;
    city: string;
    county?: string;
    country: string;
  };
  defaultBankAccount?: BankAccountAttributes;
  referencePeople: ReferencePersonFragment[];
}

export interface Props {
  onSuccessfulEdit: (freelanceProfile: FreelanceProfileFragment) => void;
  freelanceProfile: FreelanceProfileFragment;
  formId: string;
}

export default ({
  onSuccessfulEdit,
  freelanceProfile,
  formId,
}: Props): JSX.Element => {
  const showBankAccount = !freelanceProfile.id;
  const isInternational = freelanceProfile.address?.country !== "NO";

  const hasIncompleteAddress =
    !freelanceProfile.address?.line1 ||
    !freelanceProfile.address?.postalCode ||
    !freelanceProfile.address?.city ||
    !freelanceProfile.address?.country;

  const [isEditingAddress, setIsEditingAddress] =
    useState(hasIncompleteAddress);
  const { createFreelanceProfile } = useCreateFreelanceProfileMutation();
  const { updateFreelanceProfile } = useUpdateFreelanceProfileMutation();

  const onSubmit = (values: FormValues) => {
    const {
      id,
      referencePeople,
      defaultBankAccount,
      ...valuesWithoutReferencePeople
    } = values;

    const attributes = {
      ...valuesWithoutReferencePeople,
      referencePeople: referencePeople.map(referencePersonToAttributes),
      invoiceStartingNumber: freelanceProfile.invoiceStartingNumber
        ? Number(freelanceProfile.invoiceStartingNumber)
        : null,
    };

    if (id) {
      const {
        freelanceType,
        organizationNumber,
        organizationName,
        ...updateAttributes
      } = attributes;

      updateFreelanceProfile(
        { id, attributes: updateAttributes },
        onSuccessfulEdit
      );
    } else {
      if (!defaultBankAccount) return;

      const createAttributes = {
        ...attributes,
        bankAccount: {
          ...defaultBankAccount,
          accountNumber: !isInternational
            ? defaultBankAccount.accountNumber
            : getAlphanumericValueFromString(defaultBankAccount.accountNumber),
          default: true,
        },
      };
      createFreelanceProfile(
        { attributes: createAttributes },
        onSuccessfulEdit
      );
    }
  };

  const { session } = useContext(AuthContext);
  const { currentLocale } = useContext(IntlContext);
  const { t } = useTranslation("common");

  const countryCodes = getNonSanctionedCountries();

  const validationSchema = Yup.object({
    freelanceType: Yup.string<FreelanceTypeEnum>().required(),
    organizationName: Yup.string()
      .trim()
      .required(
        t(
          "freelanceProfileForm_organization.name.error.required",
          "A name is required"
        )
      ),
    organizationNumber: Yup.string()
      .trim()
      .required(
        t(
          "freelanceProfileForm_organization.orgNumber.error.required",
          "An organization number is required"
        )
      ),
    address: Yup.object({
      line1: Yup.string()
        .trim()
        .required(
          t(
            "freelanceProfileForm_organization.address.line1.error.required",
            "Address line 1 is required"
          )
        ),
      postalCode: Yup.string()
        .trim()
        .required(
          t(
            "freelanceProfileForm_organization.address.postalCode.error.required",
            "A post code is required"
          )
        ),
      city: Yup.string()
        .trim()
        .required(
          t(
            "freelanceProfileForm_organization.address.city.error.required",
            "A city is required"
          )
        ),
      country: Yup.string()
        .required(
          t(
            "freelanceProfileForm_organization.address.country.error.required",
            "A country is required"
          )
        )
        .oneOf(
          countryCodes,
          t(
            "freelanceProfileForm_organization.address.country.error.oneOf",
            "A valid country is required"
          )
        ),
    }),
    referencePeople: Yup.array()
      .of<ReferencePersonFragment>(
        Yup.object({
          name: Yup.string()
            .trim()
            .required(
              t(
                "freelanceProfileForm_organization.referencePerson.name.error.required",
                "A name is required for a reference person"
              )
            ),
          email: emailValidationSchema(t).required(),
          phone: phoneValidationSchema(t),
        })
      )
      .required(),
    defaultBankAccount: Yup.object({
      accountNumber: bankAccountNumberValidationSchema(t)
        .required()
        .when("currency", {
          is: CurrencyEnum.Nok,
          then: (schema) =>
            schema.matches(
              /^\d+$/,
              t(
                "freelanceProfileForm_organization.accountNumber.error.invalid",
                "The bank account number should only contain numbers"
              )
            ),
        }),
      currency: Yup.string()
        .required(
          t(
            "freelanceProfileForm_organization.currency.error.required",
            "A currency is required"
          )
        )
        .oneOf(Object.values(CurrencyEnum)),
    })
      .default(undefined) // Makes children optional
      .optional(),
  });

  const defaultAddress =
    freelanceProfile?.address ?? session?.account.address ?? {};
  const defaultValues = {
    id: freelanceProfile.id,
    freelanceType: FreelanceTypeEnum.Organization,
    organizationName: freelanceProfile.organizationName ?? "",
    organizationNumber: freelanceProfile.organizationNumber ?? "",
    invoiceStartingNumber: freelanceProfile.invoiceStartingNumber,
    address: {
      line1: defaultAddress.line1 ?? "",
      postalCode: defaultAddress.postalCode ?? "",
      city: defaultAddress.city ?? "",
      country: defaultAddress.country ?? "",
    },
    referencePeople: freelanceProfile.referencePeople?.length
      ? freelanceProfile?.referencePeople.map(referencePersonToAttributes)
      : [],
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    trigger,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
  });
  const { reportErrors } = useErrorLogger();
  reportErrors(errors);

  const hiddenInputs = [
    "freelanceType",
    "id",
    "organizationNumber",
    "address.line1",
    "address.line2",
    "address.postalCode",
    "address.city",
    "address.county",
    "address.country",
    "invoiceStartingNumber",
  ];

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={tw("space-y-4")}
    >
      {hiddenInputs.map((name) => (
        <input
          key={name}
          {...register(name as keyof FormValues)}
          style={{ display: "none" }}
        />
      ))}

      <div className={tw("flex", "flex-col", "space-y-4", "items-stretch")}>
        <h2 className={tw("text-lg", "font-bold")}>
          {t(
            "freelanceProfileForm_organization.infoSection.title",
            "Organisation info"
          )}
        </h2>

        <Input
          id="organizationName"
          label={t(
            "freelanceProfileForm_organization.organizationName.label",
            "Organisation name"
          )}
          errorMessage={errors.organizationName?.message}
          {...register("organizationName")}
          disabled={freelanceProfile.verified}
        />

        <Input
          id="organizationNumber"
          label={t(
            "freelanceProfileForm_organization.organizationNumber.label",
            "Organisation number"
          )}
          errorMessage={errors.organizationNumber?.message}
          {...register("organizationNumber")}
          disabled={freelanceProfile.verified}
        />

        <Divider />

        <div className={tw("my-4", "flex", "justify-between", "items-center")}>
          <h2 className={tw("text-lg", "font-bold")}>
            {t(
              "freelanceProfileForm_organization.addressSection.title",
              "Organisation address"
            )}
          </h2>

          {!isEditingAddress && (
            <Button
              id="create_org_profile_form-edit_address"
              variant="tertiary"
              size="sm"
              onClick={() => setIsEditingAddress(true)}
            >
              {t(
                "freelanceProfileForm_organization.addressSection.button.edit",
                "Edit address"
              )}
            </Button>
          )}
        </div>

        <div className={isEditingAddress ? tw("space-y-4") : undefined}>
          {[
            { name: "line1" as const, defaultLabel: "Address" },
            { name: "line2" as const, defaultLabel: "Address line 2" },
            { name: "postalCode" as const, defaultLabel: "Post code" },
            { name: "city" as const, defaultLabel: "City" },
            { name: "county" as const, defaultLabel: "County" },
          ].map(({ name, defaultLabel }) => {
            if (!isEditingAddress)
              return <p key={name}>{freelanceProfile.address?.[name]}</p>;

            const label = t(
              `freelanceProfileForm_organization.addressSection.${name}.label`,
              defaultLabel
            );
            // Values for extraction
            // t("account:freelanceProfileForm_organization.addressSection.line1.label", "Address")
            // t("account:freelanceProfileForm_organization.addressSection.line2.label", "Address line 2")
            // t("account:freelanceProfileForm_organization.addressSection.postalCode.label", "Post code")
            // t("account:freelanceProfileForm_organization.addressSection.city.label", "City")
            // t("account:freelanceProfileForm_organization.addressSection.county.label", "County")

            const inputProps = {
              errorMessage: errors.address?.[name]?.message,
              ...register(`address.${name}`),
            };

            return (
              <Input
                key={name}
                id={"address." + name}
                label={label}
                {...inputProps}
              />
            );
          })}

          {isEditingAddress ? (
            <Controller
              control={control}
              name="address.country"
              defaultValue={freelanceProfile.address?.country ?? ""}
              render={({ field }) => (
                <Select
                  id={field.name}
                  name={field.name}
                  label={t(
                    "freelanceProfileForm_organization.addressSection.country.label",
                    "Country"
                  )}
                  options={[
                    {
                      label: t(
                        "freelanceProfileForm_organization.addressSection.country.none",
                        "Select a country"
                      ),
                      value: "NOT_SELECTED",
                    },
                    ...countryCodes.map((countryCode) => ({
                      label: getCountryNameFromCode(countryCode, currentLocale),
                      value: countryCode,
                    })),
                  ]}
                  value={field.value ?? undefined}
                  onChange={field.onChange}
                  errorMessage={errors.address?.["country"]?.message}
                  disabled={freelanceProfile.verified}
                />
              )}
            />
          ) : (
            <p>
              {freelanceProfile.address?.country &&
                getCountryNameFromCode(
                  freelanceProfile.address.country,
                  currentLocale
                )}
            </p>
          )}
        </div>

        <Divider />

        <h2 className={tw("my-4", "text-lg", "font-bold")}>
          {t(
            "freelanceProfileForm_organization.referencePeopleSection.title",
            "Reference persons"
          )}
        </h2>

        <p>
          <Trans
            ns="account"
            i18nKey="freelanceProfileForm_organization.referencePeopleSection.heading"
            defaults="Please provide the name of your contact at <0>{{ orgName }}</0>. Your invoices will mention this person information."
            values={{ orgName: freelanceProfile.organizationName }}
            components={[<strong />]}
          />
        </p>
      </div>

      <ReferencePeopleSection
        // TODO: Find a way to prevent typecasting
        control={
          control as unknown as Control<ReferencePeopleSectionOrganization>
        }
        register={
          register as unknown as UseFormRegister<ReferencePeopleSectionOrganization>
        }
        referencePeopleErrors={errors.referencePeople}
      />

      {showBankAccount && (
        <div className={tw("flex", "flex-col", "space-y-4", "items-stretch")}>
          <Divider />

          <BankAccountSection
            // TODO: Find a way to prevent typecasting
            control={control as unknown as Control<FreelanceProfileFragment>}
            register={
              register as unknown as UseFormRegister<FreelanceProfileFragment>
            }
            bankAccount={freelanceProfile.defaultBankAccount}
            bankAccountErrors={errors.defaultBankAccount}
            triggerBankAccountValidation={() => trigger("defaultBankAccount")}
          />
        </div>
      )}
    </form>
  );
};
