/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ApolloError, gql, useMutation } from "@apollo/client";

import { useErrorsOrSuccessHandler } from "graphql/errorHandlers";
import { ERROR_FRAGMENT, ErrorFragment } from "graphql/fragments";
import { UseSessionOtpInput, UseSessionOtpPayload } from "graphql/types";

const USE_SESSION_OTP = gql`
  mutation ($input: UseSessionOtpInput!) {
    useSessionOtp(input: $input) {
      token
      deepLink
      errors {
        ...ErrorFragment
      }
    }
  }
  ${ERROR_FRAGMENT}
`;

interface Payload extends Pick<UseSessionOtpPayload, "token" | "deepLink"> {
  errors: ErrorFragment[];
}

export interface CreateSessionFromOtpMutation {
  useSessionOtp: Payload | null;
}

export const useCreateSessionFromOtpMutation = () => {
  const handleErrors = useErrorsOrSuccessHandler(
    "success.createdSessionFromMagicLink",
    "Signed in magically"
  );
  // Values for extraction
  // t("common:success.createdSessionFromMagicLink", "Signed in magically")

  const [mutation, { data, loading }] = useMutation<
    CreateSessionFromOtpMutation,
    { input: UseSessionOtpInput }
  >(USE_SESSION_OTP);

  const createSessionFromOtp = (
    input: UseSessionOtpInput,
    onSuccess?: (token: string, deepLink?: string | null) => void
  ) =>
    mutation({ variables: { input } })
      .then(({ data, errors }) => {
        const dataErrors = data?.useSessionOtp?.errors ?? [];
        const token = data?.useSessionOtp?.token;
        const deepLink = data?.useSessionOtp?.deepLink;

        handleErrors({
          dataErrors,
          graphqlErrors: errors,
          onSuccess: () => onSuccess && token && onSuccess(token, deepLink),
          onRetry: () => createSessionFromOtp(input, onSuccess),
          payload: { token, deepLink },
        });
      })
      .catch((_error: ApolloError) => null);

  return {
    createSessionFromOtp,
    session: data?.useSessionOtp,
    isLoading: loading,
    errors: data?.useSessionOtp?.errors ?? [],
  };
};
