import { useTranslation } from "translations";
import { tw } from "utils/tw";

import { Link } from "../../basic";

export default (): JSX.Element => {
  const { t } = useTranslation("common");

  const headerStyles = tw("bg-teal-100", "p-4", "text-center");

  const linkToApi = t(
    "header_sandbox.link.api",
    "https://sandbox.core.payoutpartner.com/partners"
  );

  return (
    <div className={headerStyles}>
      <p className={tw("text-teal-900", "font-bold")}>
        {t("header_sandbox.heading", "You are using the payoutpartner.com Sandbox.")}
      </p>

      <p className={tw("text-teal-900")}>
        Check out our API here:{" "}
        <Link
          id="sandbox_header-go_to_api"
          color="blue"
          to={linkToApi}
          isExternal
        >
          {linkToApi}
        </Link>
      </p>
    </div>
  );
};
