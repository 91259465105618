import { lazy } from "react";

import {
  AccountInfo,
  AuthSettings,
  PageSelection,
  PaymentPlan,
  PayoutSettings,
  PolicyDocuments,
  Team,
} from "./accountSettings";

const Dashboard = lazy(() => import("./Dashboard"));
const ScheduledDeletion = lazy(() => import("./ScheduledDeletion"));

export {
  AccountInfo,
  AuthSettings,
  Dashboard,
  PageSelection,
  PaymentPlan,
  PayoutSettings,
  PolicyDocuments,
  ScheduledDeletion,
  Team,
};
