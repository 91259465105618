import React from "react";

import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";

interface Context {
  logger?: {
    info: (message: string) => void;
    error: (message: string) => void;
  };
}

export const ErrorLoggerContext = React.createContext<Context>({
  logger: undefined,
});

interface Props {
  children?: React.ReactNode;
}

export const ErrorLoggerProvider = ({ children }: Props): JSX.Element => {
  // Rollbar logging:
  // const { REACT_APP_ROLLBAR_TOKEN, REACT_APP_ROLLBAR_ENVIRONMENT } =
  //   process.env;

  // const logger = REACT_APP_ROLLBAR_TOKEN
  //   ? new Rollbar({
  //       accessToken: REACT_APP_ROLLBAR_TOKEN,
  //       environment: REACT_APP_ROLLBAR_ENVIRONMENT ?? "unknown",
  //     })
  //   : undefined;

  const {
    REACT_APP_NEWRELIC_ACCOUNT_ID,
    REACT_APP_NEWRELIC_APP_ID,
    REACT_APP_NEWRELIC_LICENSE_KEY,
  } = process.env;

  const shouldLog =
    REACT_APP_NEWRELIC_ACCOUNT_ID &&
    REACT_APP_NEWRELIC_APP_ID &&
    REACT_APP_NEWRELIC_LICENSE_KEY;
  if (!shouldLog)
    return (
      <ErrorLoggerContext.Provider value={{}}>
        {children}
      </ErrorLoggerContext.Provider>
    );

  const options = {
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ["bam.eu01.nr-data.net"] },
    },
    info: {
      beacon: "bam.eu01.nr-data.net",
      errorBeacon: "bam.eu01.nr-data.net",
      licenseKey: REACT_APP_NEWRELIC_LICENSE_KEY,
      applicationID: REACT_APP_NEWRELIC_APP_ID,
      sa: 1,
    },
    loader_config: {
      accountID: REACT_APP_NEWRELIC_ACCOUNT_ID,
      trustKey: REACT_APP_NEWRELIC_ACCOUNT_ID,
      agentID: REACT_APP_NEWRELIC_APP_ID,
      licenseKey: REACT_APP_NEWRELIC_LICENSE_KEY,
      applicationID: REACT_APP_NEWRELIC_APP_ID,
    },
    features: ["Logging"],
  };

  const newRelic = new BrowserAgent(options);

  const logger = {
    info: (message: string) => newRelic.log(message, { level: "INFO" }),
    error: (message: string) => newRelic.log(message, { level: "ERROR" }),
  };

  return (
    <ErrorLoggerContext.Provider value={{ logger }}>
      {children}
    </ErrorLoggerContext.Provider>
  );
};
