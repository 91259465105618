import { gql } from "@apollo/client";

import { Features, Session, User } from "graphql/types";

import { ACCOUNT_FRAGMENT, AccountFragment } from "./account";
import { PHONE_NUMBER_FRAGMENT, PhoneNumberFragment } from "./phoneNumber";
import { USER_FRAGMENT, UserFragment } from "./user";

export const SESSION_FRAGMENT = gql`
  fragment SessionFragment on Session {
    account {
      ...AccountFragment
    }
    accounts {
      id
      slug
      name
      email
      accountType
      hasFreelanceProfiles
      role
      canManage
      canManageDepartments
      logo {
        ...AttachmentFragment
      }
      symbol {
        ...AttachmentFragment
      }
      symbolDisplayPath
      features {
        invoices
        payouts
        workflows
      }
      primaryUser {
        id
        email
        firstName
        lastName
        confirmed
      }
      deletionScheduledOn
    }
    administrator
    phoneNumber {
      ...PhoneNumberFragment
    }
    user {
      ...UserFragment
    }
    hasFreelanceProfiles
    hasIndividualFreelanceProfile
    role
    needsOnboarding
    hasIdentity
  }
  ${ACCOUNT_FRAGMENT}
  ${PHONE_NUMBER_FRAGMENT}
  ${USER_FRAGMENT}
`;

interface SessionAccount
  extends Pick<
    AccountFragment,
    | "id"
    | "slug"
    | "name"
    | "email"
    | "accountType"
    | "hasFreelanceProfiles"
    | "logo"
    | "symbol"
    | "symbolDisplayPath"
    | "role"
    | "canManage"
    | "canManageDepartments"
    | "deletionScheduledOn"
  > {
  features: Pick<Features, "invoices" | "payouts" | "workflows">;
  primaryUser?: Pick<
    User,
    "id" | "email" | "firstName" | "lastName" | "confirmed"
  >;
}

export interface SessionFragment
  extends Pick<
    Session,
    | "administrator"
    | "phoneNumber"
    | "user"
    | "hasFreelanceProfiles"
    | "hasIndividualFreelanceProfile"
    | "role"
    | "needsOnboarding"
    | "hasIdentity"
  > {
  account: AccountFragment;
  accounts: SessionAccount[];
  phoneNumber: PhoneNumberFragment;
  user: UserFragment;
}
