import { Suspense, useContext } from "react";
import { useLocation } from "react-router-dom";

import { AuthContext } from "providers/Authentication";
import { TailwindContext } from "providers/Tailwind";
import { checkIsRoutePath, useRoutePaths } from "utils";
import { tw } from "utils/tw";

import { Spinner } from "../../basic";
import AdminHeader from "./AdminHeader";
import AppHeader from "./AppHeader";
import AppTabBar from "./AppTabBar";
import BannerSection from "./BannerSection";
import OnboardingFooter from "./OnboardingFooter";
import OnboardingHeader from "./OnboardingHeader";
import SandboxHeader from "./SandboxHeader";
import SideMenu from "./SideMenu";

interface Props {
  children: React.ReactNode;
}

export default ({ children }: Props): JSX.Element => {
  const { isLoggedIn, session } = useContext(AuthContext);
  const { isMd } = useContext(TailwindContext);

  const { pathname } = useLocation();
  const getRoutePath = useRoutePaths();

  const isHome =
    pathname === getRoutePath({ module: "common", routeName: "HOME" });
  const isPrivacy = checkIsRoutePath(pathname, {
    module: "common",
    routeName: "PRIVACY",
  });
  const isTerms = checkIsRoutePath(pathname, {
    module: "common",
    routeName: "TERMS",
  });

  // const isIframe = window.location !== window.parent.location;
  if (isHome || isPrivacy || isTerms) return <>{children}</>;

  const isEnrolmentWizard = checkIsRoutePath(pathname, {
    module: "recruitmore",
    routeName: "ENROLMENT_WIZARD",
  });
  const isWizard = isEnrolmentWizard;

  const isTokenHandler = checkIsRoutePath(pathname, {
    module: "common",
    routeName: "TOKEN_HANDLER",
  });

  if (isWizard || isTokenHandler) return <>{children}</>;

  const isAdmin = session?.administrator;

  // const hasReferrer = typeof session?.user.referrer === "string";
  // const hasOccupation = typeof session?.user.occupation === "string";
  const isConfirmed = session?.user.confirmed === true;

  const isRestricted = isAdmin ? false : !isConfirmed;

  const isOnboarding = !isLoggedIn || isRestricted;

  const isSandbox = window.location.hostname === "sandbox.my.payoutpartner.com";

  const outerWrapperStyles = tw("min-h-screen", "flex", "flex-row", {
    "pt-16": !isMd && Boolean(session), // Space behind AppTabBar
  });
  const innerWrapperStyles = tw("min-h-screen", "flex-1", "flex", "flex-col", {
    // Prevents sticky headers from working on tier selection page
    "overflow-x-hidden": isLoggedIn,
  });

  return (
    <div className={outerWrapperStyles}>
      {isMd && isLoggedIn && session && !isRestricted && (
        <SideMenu session={session} />
      )}

      <div className={innerWrapperStyles}>
        {isSandbox && <SandboxHeader />}

        <BannerSection />

        {isOnboarding ? (
          <OnboardingHeader />
        ) : (
          <>
            {isAdmin && <AdminHeader session={session} />}

            {isMd && <AppHeader />}
          </>
        )}

        <div className={tw("flex-1", "flex", "flex-col")}>
          <Suspense fallback={<Spinner />}>{children}</Suspense>
        </div>

        {isOnboarding ? (
          <OnboardingFooter />
        ) : (
          !isMd && session && <AppTabBar />
        )}
      </div>
    </div>
  );
};
