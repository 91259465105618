import { AccountFragment, SimplifiedAccountFragment } from "graphql/fragments";

export default ({
  name,
  ...account
}: AccountFragment | SimplifiedAccountFragment) => {
  if (name) return name;

  const hasUser = Object.prototype.hasOwnProperty.call(account, "primaryUser");
  if (!hasUser) return "-";

  const { primaryUser } = account as SimplifiedAccountFragment;
  if (!primaryUser?.firstName || !primaryUser.lastName) return "-";

  return `${primaryUser.firstName} ${primaryUser.lastName}`;
};
