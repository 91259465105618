import { IconType } from "react-icons";
import {
  HiOutlineAcademicCap,
  HiOutlineCash,
  HiOutlineFingerPrint,
  HiOutlinePencil,
  HiOutlineUserGroup,
  HiOutlineViewGridAdd,
  HiPlus,
} from "react-icons/hi";

import { Button, Link } from "components/common/basic";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

interface Props {
  cards?: {
    id: string;
    Icon: IconType;
    title: string;
    description: string;
    readMore: string;
    isActive: boolean;
  }[];
}

const testCards = [
  {
    id: "EARN_MORE",
    Icon: HiOutlineCash,
    title: "Earnmore",
    description: "Send invoices",
    readMore: "https://en.support.payoutpartner.com",
    isActive: true,
  },
  {
    id: "CHECK_MORE",
    Icon: HiOutlineFingerPrint,
    title: "Checkmore",
    description: "Background check anyone",
    readMore: "https://en.support.payoutpartner.com",
    isActive: false,
  },
  {
    id: "STAFF_MORE",
    Icon: HiOutlineUserGroup,
    title: "Staffmore",
    description: "Onboard & payout",
    readMore: "https://en.support.payoutpartner.com",
    isActive: false,
  },
  {
    id: "LEARN_MORE",
    Icon: HiOutlineAcademicCap,
    title: "Learnmore",
    description: "Create courses for your staff",
    readMore: "https://en.support.payoutpartner.com",
    isActive: false,
  },
  {
    id: "SIGN_MORE",
    Icon: HiOutlinePencil,
    title: "Signmore",
    description: "Get documents signed",
    readMore: "https://en.support.payoutpartner.com",
    isActive: true,
  },
];

export default ({ cards = testCards }: Props): JSX.Element => {
  const { t } = useTranslation("common");
  const isHidden = false;

  if (isHidden) return <></>;

  const title = t("header_loggedIn.workspaceMenu.title", "Workspace");
  const description = t(
    "header_loggedIn.workspaceMenu.description",
    "Add or hide products you or your team would like to use. They are free to test so no harm is done."
  );

  return (
    <div className={tw("bg-silver", "p-4", "space-y-4")}>
      <div className={tw("pl-4", "flex", "space-x-3")}>
        <HiOutlineViewGridAdd
          size="24px"
          className={tw("text-gray-100", "shrink-0")}
        />

        <div>
          <h3 className={tw("text-gray-700")}>{title}</h3>

          <p className={tw("text-gray-500")}>{description}</p>
        </div>
      </div>

      <ul
        className={tw(
          "pl-12",
          "md:pl-4",
          "grid",
          "grid-cols-1",
          "md:grid-cols-2",
          "gap-4",
          "md:gap-2"
        )}
      >
        {cards.map(({ id, Icon, title, description, readMore, isActive }) => (
          <li
            key={id}
            className={tw("bg-white", "w-full", "p-3", "flex", "space-x-2")}
          >
            <div
              className={tw(
                "self-baseline",
                "p-1.5",
                "rounded-full",
                "shrink-0",
                isActive ? "bg-teal-100" : "bg-silver"
              )}
            >
              <Icon
                size="24px"
                className={tw(isActive ? "text-teal-900" : "text-gray-500")}
              />
            </div>

            <div className={tw("w-full")}>
              <h4
                className={tw(
                  "font-bold",
                  isActive ? "text-teal-900" : "text-gray-900"
                )}
              >
                {title}
              </h4>

              <p className={tw("text-xs", "text-gray-700", "mt-1", "mb-2")}>
                {description}
              </p>

              <div className={tw("flex", "justify-between", "items-center")}>
                <Link
                  id="workspace-readmore"
                  to={readMore}
                  isExternal
                  color="gray"
                  className={tw("text-xs")}
                >
                  {t(
                    "header_loggedIn.workspaceMenu.card.readMore",
                    "Read more"
                  )}
                </Link>

                {isActive ? (
                  <button
                    type="button"
                    className={tw(
                      "text-xs",
                      "text-gray-500",
                      "hover:text-gray-300",
                      "underline"
                    )}
                  >
                    {t("header_loggedIn.workspaceMenu.card.hide", "Hide")}
                  </button>
                ) : (
                  <Button
                    id="workspace-add"
                    size="md"
                    variant="secondary"
                    LeadingIcon={HiPlus}
                  >
                    {t("header_loggedIn.workspaceMenu.card.add", "Add")}
                  </Button>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
