import { lazy } from "react";

const BankIDVerification = lazy(() => import("./BankIDVerification"));
const ComponentLibrary = lazy(() => import("./ComponentLibrary"));
const CriiptoCallback = lazy(() => import("./CriiptoCallback"));
const ErrorPage = lazy(() => import("./ErrorPage"));
const GateKeeper = lazy(() => import("./GateKeeper"));
const IdentityVerification = lazy(() => import("./IdentityVerification"));
const Privacy = lazy(() => import("./Privacy"));
const Terms = lazy(() => import("./Terms"));

export {
  BankIDVerification,
  ComponentLibrary,
  CriiptoCallback,
  ErrorPage,
  GateKeeper,
  IdentityVerification,
  Privacy,
  Terms,
};
