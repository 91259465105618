import { useContext, useEffect, useRef } from "react";
import { HiOutlineMenu, HiQuestionMarkCircle } from "react-icons/hi";
import { useLocation } from "react-router-dom";

import { Link } from "components/common/basic";
import { LanguageSelect } from "components/common/composite";
import { useMenu } from "hooks";
import { TailwindContext } from "providers/Tailwind";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

import MenuList from "./MenuList/MenuList";
import { MenuItem } from "./MenuList/types";

interface Props {
  left: JSX.Element | null;
  right: JSX.Element | null;
  menuList: MenuItem[] | JSX.Element;
  showMenuToggle: boolean;
  showHelpLink?: boolean;
  isFlat?: boolean;
}

export default ({
  left,
  right,
  menuList,
  showMenuToggle,
  showHelpLink = false,
  isFlat = false,
}: Props): JSX.Element => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [isShowingMenu, setIsShowingMenu] = useMenu(menuRef);
  const toggleMenu = () => setIsShowingMenu(!isShowingMenu);

  const { isMd } = useContext(TailwindContext);
  const { pathname } = useLocation();
  const { t } = useTranslation("common");

  useEffect(() => {
    setIsShowingMenu(false);
  }, [pathname]);

  const headerStyles = tw(
    "sticky",
    "top-0",
    "z-40",
    "bg-white",
    "flex",
    "items-stretch",
    "justify-between",
    "py-4",
    "px-6",
    { shadow: !isFlat }
  );

  const navigationStyles = tw("flex", "items-center", "space-x-4");

  const fullscreenMenuStyles = tw(
    "absolute",
    "top-full",
    "left-0",
    "border-t-2",
    "border-silver",
    "bg-white",
    "h-screen",
    "w-screen"
  );
  const dropdownMenuStyles = tw(
    "origin-top-right",
    "absolute",
    "right-0",
    "mt-2",
    "w-60",
    "rounded-md",
    "shadow-lg",
    "bg-white",
    "ring-1",
    "ring-gray-900",
    "ring-opacity-5",
    "focus:outline-none"
  );

  const navMenu = Array.isArray(menuList) ? (
    <MenuList menuItems={menuList} />
  ) : (
    menuList
  );

  return (
    <header className={headerStyles}>
      <div className={navigationStyles}>{left}</div>

      <div className={navigationStyles}>
        {right}

        {showHelpLink && (
          <Link
            id="header_template-go_to_help"
            to={t("common:links.help", "https://en.support.payoutpartner.com")}
            isExternal
            unstyled
          >
            <HiQuestionMarkCircle size="28px" className={tw("text-gray-900")} />
          </Link>
        )}

        <LanguageSelect />

        {showMenuToggle && (
          <div className={isMd ? tw("relative") : undefined} ref={menuRef}>
            <button
              aria-label="menu"
              aria-expanded={isShowingMenu}
              onClick={toggleMenu}
              className={tw(
                "flex",
                "gap-2",
                "items-center",
                "focus-visible:outline-none",
                "focus-visible:rounded",
                "focus-visible:ring-2",
                "focus-visible:ring-offset-4",
                "focus-visible:ring-gray-900"
              )}
            >
              <span
                className={tw(
                  "text-sm",
                  "font-semibold",
                  "text-deepBlue-700",
                  "max-w-xs",
                  "truncate"
                )}
              >
                {t("header.menu.label", "Menu")}
              </span>

              <HiOutlineMenu size={20} className={tw("text-deepBlue-700")} />
            </button>

            {isShowingMenu && (
              <nav className={isMd ? dropdownMenuStyles : fullscreenMenuStyles}>
                {navMenu}

                {/* TODO: Show workspace in header menu */}
                {/* <Workspace /> */}
              </nav>
            )}
          </div>
        )}
      </div>
    </header>
  );
};
