import { useEffect } from "react";
import {
  Control,
  FieldErrors,
  UseFormRegister,
  useFieldArray,
} from "react-hook-form";
import { HiOutlineTrash } from "react-icons/hi";

import { CardBody, DividerButton, Input } from "components/common/basic";
import { ClientFragment, FreelanceProfileFragment } from "graphql/fragments";
import { useTranslation } from "translations";
import { tw } from "utils/tw";

export interface Organization
  extends Omit<ClientFragment, "address">,
    Omit<FreelanceProfileFragment, "address"> {}

interface Props {
  control: Control<Organization>;
  register: UseFormRegister<Organization>;
  referencePeopleErrors?: FieldErrors<Organization>["referencePeople"];
}

export default ({
  control,
  register,
  referencePeopleErrors,
}: Props): JSX.Element => {
  const { t } = useTranslation("common");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "referencePeople",
  });

  const addPerson = () =>
    append({
      name: "",
      email: "",
      phone: "",
      referenceNumber: "",
    });

  useEffect(() => {
    if (!fields.length) addPerson();
  }, [fields.length]);

  return (
    <>
      <CardBody className={tw("items-stretch")}>
        {fields.map(({ id, name, email, phone }, index) => {
          const removePerson = () => remove(index);
          const prefix = `referencePeople.${index}` as const;

          const fieldErrors = referencePeopleErrors?.[index];

          return (
            <div
              key={id}
              className={tw(
                "rounded-lg",
                "border",
                "border-deepBlue-100",
                "p-4",
                "space-y-4"
              )}
            >
              <button className={tw("block", "ml-auto")} onClick={removePerson}>
                <HiOutlineTrash size={20} />
              </button>

              <Input
                id={`${prefix}.name`}
                {...register(`${prefix}.name`)}
                label={t(
                  "referencePersonForm.name.label",
                  "Name of reference person"
                )}
                errorMessage={fieldErrors?.name?.message}
                defaultValue={name}
              />

              <Input
                id={`${prefix}.email`}
                {...register(`${prefix}.email`)}
                type="email"
                label={t("referencePersonForm.email.label", "Email address")}
                errorMessage={fieldErrors?.email?.message}
                defaultValue={email}
              />

              <Input
                id={`${prefix}.phone`}
                {...register(`${prefix}.phone`)}
                label={t("referencePersonForm.phone.label", "Phone number")}
                errorMessage={fieldErrors?.phone?.message}
                defaultValue={phone ?? ""}
              />

              <Input
                id={`${prefix}.referenceNumber`}
                {...register(`${prefix}.referenceNumber`)}
                label={t(
                  "referencePersonForm.referenceNumber.label",
                  "Reference number"
                )}
                errorMessage={fieldErrors?.referenceNumber?.message}
                hint={t("referencePersonForm.referenceNumber.hint", "Optional")}
              />
            </div>
          );
        })}
      </CardBody>

      <DividerButton
        label={t(
          "referencePersonForm.button.addPerson",
          "+ Add another person"
        )}
        onClick={addPerson}
      />
    </>
  );
};
