import { IconBaseProps } from "react-icons";

export default ({
  size,
  strokeWidth = "1.5",
  ...props
}: IconBaseProps): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M26.6667 7.99996C26.943 7.99996 27.2177 7.99296 27.4905 7.97912C27.8231 9.26389 28 10.6113 28 12C28 19.4554 22.9009 25.7198 16 27.4959C9.09909 25.7198 4 19.4554 4 12C4 10.6112 4.17694 9.26377 4.50951 7.97896C4.78237 7.9928 5.05703 7.9998 5.33333 7.9998C9.43118 7.9998 13.1692 6.45928 15.9999 3.92578C18.8306 6.45938 22.5687 7.99996 26.6667 7.99996Z"
    />
    <path
      fill="currentColor"
      d="M15.701 20.4382V11.9902L14.549 12.3422C14.437 12.3742 14.293 12.3902 14.197 12.3902C13.733 12.3902 13.333 12.0062 13.333 11.5582C13.333 11.1422 13.589 10.8222 13.989 10.7102L15.685 10.1662C16.069 10.0542 16.357 9.99023 16.645 9.99023H16.677C17.237 9.99023 17.653 10.4222 17.653 10.9662V20.4382C17.653 20.9822 17.221 21.4142 16.677 21.4142C16.149 21.4142 15.701 20.9822 15.701 20.4382Z"
    />
  </svg>
);
