import { useContext } from "react";
import { HiOutlineCollection, HiOutlineUserGroup } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";

import { Divider, Select, Tabs } from "components/common/basic";
import { HeadingWithNotice } from "components/common/composite";
import { Page } from "components/common/wrappers";
import { AuthContext } from "providers/Authentication";
import { TailwindContext } from "providers/Tailwind";
import { useTranslation } from "translations";
import { useRoutePaths } from "utils";
import { tw } from "utils/tw";

import Departments from "./Departments";
import Members from "./Members";

enum TabEnum {
  Members = "MEMBERS",
  Departments = "DEPARTMENTS",
}

export default (): JSX.Element | null => {
  const { t } = useTranslation("account");
  const { session } = useContext(AuthContext);
  const { isLg } = useContext(TailwindContext);
  const { pathname } = useLocation();
  const getRoutePath = useRoutePaths();
  const navigate = useNavigate();

  const activeTab = (() => {
    switch (pathname) {
      case getRoutePath({
        module: "account",
        routeName: "SETTINGS_TEAM_MEMBERS",
      }):
        return TabEnum.Members;

      case getRoutePath({
        module: "account",
        routeName: "SETTINGS_TEAM_DEPARTMENTS",
      }):
        return TabEnum.Departments;

      default:
        return TabEnum.Members;
    }
  })();

  const getPageForTab = (tab: TabEnum) => {
    switch (tab) {
      case TabEnum.Members:
        return <Members />;

      case TabEnum.Departments:
        return <Departments />;

      default:
        return <Members />;
    }
  };

  const getRouteForTab = (tab: TabEnum) => {
    switch (tab) {
      case TabEnum.Members:
        return getRoutePath({
          module: "account",
          routeName: "SETTINGS_TEAM_MEMBERS",
        });

      case TabEnum.Departments:
        return getRoutePath({
          module: "account",
          routeName: "SETTINGS_TEAM_DEPARTMENTS",
        });

      default:
        return getRoutePath({ module: "account", routeName: "SETTINGS_TEAM" });
    }
  };

  const tabs = [
    {
      label: t("accountSettings_org.team.tab_members.label", "Members"),
      value: TabEnum.Members,
      badgeValue: session?.account.counts?.members,
      LeadingIcon: HiOutlineUserGroup,
      link: {
        id: "accountSettings_team-go_to_members" as const,
        to: getRouteForTab(TabEnum.Members),
      },
    },
    {
      label: t("accountSettings_org.team.tab_departments.label", "Departments"),
      value: TabEnum.Departments,
      badgeValue: session?.account.counts?.departments,
      LeadingIcon: HiOutlineCollection,
      link: {
        id: "accountSettings_team-go_to_departments" as const,
        to: getRouteForTab(TabEnum.Departments),
      },
    },
  ];

  return (
    <Page title={t("accountSettings_org.team.pageTitle", "Team settings")}>
      <div className={tw("space-y-4")}>
        {/* <AccountCrumbs
          currentPage={t("accountSettings_org.team.breadcrumb", "Team")}
        /> */}

        <div
          className={tw(
            "mb-4",
            "flex",
            "flex-col",
            "w-full",
            "gap-4",
            "lg:flex-row",
            "lg:justify-between",
            "lg:items-start"
          )}
        >
          <HeadingWithNotice
            heading={t("accountSettings_org.team.header", "Team")}
            LeadingIcon={HiOutlineUserGroup}
            notice={{
              id: "orgAccountSettings_team-headerNotice",
              heading: t(
                "accountSettings_org.team.noticeHeader",
                "Team overview"
              ),
              body: t(
                "accountSettings_org.team.noticeDescription",
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
              ),
            }}
          />
        </div>

        <Divider />

        <div>
          {isLg ? (
            <div className={tw("w-full")}>
              <Tabs
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={() => null}
                size="md"
              />

              <Divider />
            </div>
          ) : (
            <Select
              id="tabs"
              name="tabs"
              label={t("payoutService.tabSelect.label", "Select tab")}
              hideLabel
              options={tabs}
              value={activeTab}
              onChange={(event) =>
                navigate(getRouteForTab(event.target.value as TabEnum))
              }
            />
          )}
        </div>
      </div>

      <div className={tw("mt-8")}>{getPageForTab(activeTab)}</div>
    </Page>
  );
};
