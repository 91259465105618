import React from "react";

import { tw } from "utils/tw";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "type" | "className"
  > {
  id: string;
  label: string;
  helpText?: string | JSX.Element;
  errorMessage?: string;
}

export default React.forwardRef(
  (
    {
      id,
      label,
      helpText,
      errorMessage,
      ...props
    }: React.PropsWithChildren<Props>,
    ref: React.ForwardedRef<HTMLInputElement>
  ): JSX.Element => {
    const hasHelpText = helpText !== undefined;
    const hasError = errorMessage !== undefined;

    const helpTextId = hasHelpText ? `${id}-help` : "";
    const errorId = hasError ? `${id}-error` : "";
    const inputDescriptionIDs =
      hasHelpText || hasError ? `${helpTextId} ${errorId}`.trim() : undefined;

    const radioStyles = tw("flex", "items-start");

    const inputBase = tw(
      "mt-0.5",
      "h-4",
      "w-4",
      "border-gray-300",
      "focus:ring-gray-900"
    );
    const inputDefault = tw("text-gray-900", "hover:text-gray-700");
    const inputDisabled = tw("bg-gray-100", "text-gray-100");
    const inputStyles = tw(
      inputBase,
      props.disabled ? inputDisabled : inputDefault
    );

    const labelContainerStyles = tw("ml-3", "text-sm");
    const labelStyles = tw("text-deepBlue-900", "font-semibold");

    return (
      <div className={radioStyles}>
        <input
          {...props}
          id={id}
          ref={ref}
          type="radio"
          className={inputStyles}
          aria-describedby={inputDescriptionIDs}
        />

        <div className={labelContainerStyles}>
          <label htmlFor={id} className={labelStyles}>
            {label}
          </label>

          {helpTextId && (
            <p id={helpTextId} className={tw("text-deepBlue-500")}>
              {helpText}
            </p>
          )}
        </div>
      </div>
    );
  }
);
