import { IconBaseProps } from "react-icons";

export default ({
  size,
  strokeWidth = "1.5",
  ...props
}: IconBaseProps): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M26.6667 7.99996C26.943 7.99996 27.2177 7.99296 27.4905 7.97912C27.8231 9.26389 28 10.6113 28 12C28 19.4554 22.9009 25.7198 16 27.4959C9.09909 25.7198 4 19.4554 4 12C4 10.6112 4.17694 9.26377 4.50951 7.97896C4.78237 7.9928 5.05703 7.9998 5.33333 7.9998C9.43118 7.9998 13.1692 6.45928 15.9999 3.92578C18.8306 6.45938 22.5687 7.99996 26.6667 7.99996Z"
    />
    <path
      fill="currentColor"
      d="M16.112 21.5248C14.464 21.5248 13.232 20.9488 12.32 20.0688C12.144 19.9248 12 19.6528 12 19.3648C12 18.8528 12.432 18.4208 12.944 18.4208C13.232 18.4208 13.44 18.5328 13.6 18.6768C14.304 19.3808 15.104 19.7488 16.144 19.7488C17.248 19.7488 18.08 19.0768 18.08 18.0528V18.0208C18.08 16.9168 17.072 16.2928 15.504 16.2928H15.104C14.64 16.2928 14.256 15.9088 14.256 15.4448C14.256 15.1888 14.368 14.9488 14.672 14.6448L17.296 11.8448H13.232C12.752 11.8448 12.368 11.4608 12.368 10.9968C12.368 10.5168 12.752 10.1328 13.232 10.1328H18.96C19.52 10.1328 19.936 10.4688 19.936 10.9968C19.936 11.4608 19.712 11.7328 19.376 12.0688L16.736 14.8048C18.432 15.0128 20.032 15.8768 20.032 17.9408V17.9728C20.032 20.0368 18.448 21.5248 16.112 21.5248Z"
    />
  </svg>
);
