import { IconBaseProps } from "react-icons";

export default ({
  size,
  strokeWidth = "1.5",
  ...props
}: IconBaseProps): JSX.Element => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M27.1667 7.99996C27.443 7.99996 27.7177 7.99296 27.9905 7.97912C28.3231 9.26389 28.5 10.6113 28.5 12C28.5 19.4554 23.4009 25.7198 16.5 27.4959C9.59909 25.7198 4.5 19.4554 4.5 12C4.5 10.6112 4.67694 9.26377 5.00951 7.97896C5.28237 7.9928 5.55703 7.9998 5.83333 7.9998C9.93118 7.9998 13.6692 6.45928 16.4999 3.92578C19.3306 6.45938 23.0687 7.99996 27.1667 7.99996Z"
    />
    <path
      fill="currentColor"
      d="M12.5 20.4047C12.5 20.0207 12.676 19.7007 13.012 19.4287L16.452 16.5007C17.988 15.2047 18.548 14.4847 18.548 13.4767C18.548 12.3727 17.764 11.7327 16.74 11.7327C15.844 11.7327 15.22 12.1487 14.532 12.9807C14.388 13.1407 14.164 13.2847 13.844 13.2847C13.348 13.2847 12.948 12.8847 12.948 12.3887C12.948 12.1807 13.028 11.9567 13.172 11.7807C14.084 10.6447 15.124 9.97266 16.884 9.97266C19.076 9.97266 20.58 11.3167 20.58 13.3167V13.3487C20.58 15.1087 19.652 16.0687 17.668 17.6847L15.364 19.6047H19.908C20.388 19.6047 20.772 19.9887 20.772 20.4687C20.772 20.9487 20.388 21.3327 19.908 21.3327H13.556C12.948 21.3327 12.5 20.9807 12.5 20.4047Z"
    />
  </svg>
);
