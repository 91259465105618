import { useContext } from "react";
import { HiOutlineCreditCard } from "react-icons/hi";

import { Button, Card, Icon, Link } from "components/common/basic";
import { HeadingWithNotice } from "components/common/composite";
import { Page } from "components/common/wrappers";
import { AuthContext } from "providers/Authentication";
import { Trans, useTranslation } from "translations";
import getPricePlansByTag from "utils/getPricePlansByTag";
import { tw } from "utils/tw";

export default (): JSX.Element | null => {
  const { t } = useTranslation("account");
  const { session } = useContext(AuthContext);

  if (!session) return null;

  const { tag } = session.account;
  if (!tag) return null;

  const plans = getPricePlansByTag(tag, t);

  return (
    <Page
      title={t("accountSettings_org.paymentPlan.pageTitle", "Payment plan")}
    >
      <div className={tw("space-y-8")}>
        <div className={tw("space-y-4")}>
          {/* <AccountCrumbs
            currentPage={t(
              "accountSettings_org.paymentPlan.breadcrumb",
              "Payment plan"
            )}
          /> */}

          <HeadingWithNotice
            heading={t(
              "accountSettings_org.paymentPlan.header",
              "Payment plan"
            )}
            LeadingIcon={HiOutlineCreditCard}
            notice={{
              id: "orgAccountSettings_paymentPlan-headerNotice",
              heading: t(
                "accountSettings_org.paymentPlan.noticeHeader",
                "Payment plan overview"
              ),
              body: t(
                "accountSettings_org.paymentPlan.noticeDescription",
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
              ),
            }}
          />
        </div>

        <div
          className={tw(
            "flex",
            "flex-col",
            "space-y-8",
            "lg:flex-row",
            "lg:space-y-0",
            "lg:space-x-28"
          )}
        >
          <Card
            isFlat
            contentPadding="p-4"
            className={tw(
              "border",
              "border-deepBlue-100",
              "shrink-0",
              "w-full",
              "lg:w-72"
            )}
          >
            <div className={tw("space-y-1")}>
              <h2 className={tw("text-lg", "font-bold", "text-deepBlue-900")}>
                {t(
                  "accountSettings_org.paymentPlan.card.paymentMethod.heading",
                  "Payment method"
                )}
              </h2>

              <p className={tw("text-sm", "text-deepBlue-500")}>
                {t(
                  "accountSettings_org.paymentPlan.card.paymentMethod.helpText",
                  "This is the organisation we will send the invoice to"
                )}
              </p>
            </div>

            <div>
              <p className={tw("text-sm", "text-deepBlue-300")}>
                {t(
                  "accountSettings_org.paymentPlan.card.paymentMethod.nameLabel",
                  "Name:"
                )}
              </p>
              <p className={tw("text-lg", "font-bold", "text-deepBlue-900")}>
                {session.account.name ?? "-"}
              </p>
            </div>

            <div>
              <p className={tw("text-sm", "text-deepBlue-300")}>
                {t(
                  "accountSettings_org.paymentPlan.card.paymentMethod.orgLabel",
                  "Org:"
                )}
              </p>
              <p className={tw("text-lg", "font-bold", "text-deepBlue-900")}>
                {session.account.organizationNumber}
              </p>

              <p className={tw("text-deepBlue-500", "text-sm", "mt-1")}>
                {session.account.organizationName}
              </p>
            </div>
          </Card>

          <div className={tw("space-y-4")}>
            <div className={tw("max-w-xl", "space-y-2")}>
              <h2 className={tw("text-lg", "font-bold", "text-deepBlue-900")}>
                {t(
                  "accountSettings_org.paymentPlan.subscription.heading",
                  "Your subscription"
                )}
              </h2>

              <p className={tw("text-sm", "text-deepBlue-500")}>
                {t(
                  "accountSettings_org.paymentPlan.subscription.description",
                  "This is your current subscription plan with Manymore. Contact us at sales@payoutpartner.com to change your plan."
                )}
              </p>
            </div>

            <div
              className={tw(
                "flex",
                "flex-col",
                "md:flex-row",
                "md:flex-wrap",
                "gap-4"
              )}
            >
              {plans.map(({ plan, label, candidates, teamUsers, price }) => {
                const isActivePlan = plan === session.account.pricePlan;
                const isPremium = price === -1;

                return (
                  <div
                    key={plan}
                    className={tw(
                      "w-48",
                      "p-4",
                      "flex",
                      "flex-col",
                      "space-y-3",
                      "rounded-lg",
                      "border",
                      "border-gray-100",
                      { "bg-blue-100": isActivePlan }
                    )}
                  >
                    <div className={tw("space-y-1")}>
                      <div className={tw("flex", "justify-between")}>
                        <p
                          className={tw(
                            "text-sm",
                            "font-semibold",
                            "text-deepBlue-900"
                          )}
                        >
                          {label}
                        </p>

                        {isActivePlan && (
                          <Icon
                            icon="HiCheckCircle"
                            isFilled
                            size={20}
                            color="text-gray-900"
                          />
                        )}
                      </div>

                      {!isPremium && (
                        <p className={tw("text-deepBlue-700", "text-sm")}>
                          {t(
                            "accountSettings_org.paymentPlan.subscription.plan.candidates",
                            {
                              count: candidates,
                              defaultValue_one: "{{ count }} candidate,",
                              defaultValue_other: "{{ count }} candidates,",
                            }
                          )}
                        </p>
                      )}

                      {!isPremium && (
                        <p className={tw("text-deepBlue-700", "text-sm")}>
                          {t(
                            "accountSettings_org.paymentPlan.subscription.plan.teamUsers",
                            {
                              count: teamUsers,
                              defaultValue_one: "{{ count }} team user",
                              defaultValue_other: "{{ count }} team users",
                            }
                          )}
                        </p>
                      )}
                    </div>

                    <div
                      className={tw(
                        "flex-1",
                        "flex",
                        "flex-col",
                        "space-y-2",
                        "justify-between"
                      )}
                    >
                      {isPremium ? (
                        <p
                          className={tw(
                            "text-deepBlue-700",
                            "text-sm",
                            "whitespace-pre-wrap"
                          )}
                        >
                          {isActivePlan
                            ? t(
                                "accountSettings_org.paymentPlan.premium_active.details",
                                "You are on a custom plan"
                              )
                            : t(
                                "accountSettings_org.paymentPlan.premium_inactive.details",
                                "Contact us for a custom plan"
                              )}
                        </p>
                      ) : (
                        <p className={tw("text-deepBlue-700", "text-xs")}>
                          <Trans
                            ns="account"
                            i18nKey="accountSettings_org.paymentPlan.subscription.plan.price"
                            defaults="<strong>{{ price }},-</strong> (ink mva) per year"
                            values={{ price }}
                            components={{
                              strong: (
                                <strong
                                  className={tw(
                                    "text-sm",
                                    "text-deepBlue-900",
                                    "font-regular"
                                  )}
                                />
                              ),
                            }}
                          />
                        </p>
                      )}

                      {isActivePlan ? null : (
                        <Link
                          id="accountSettings__plans-get_in_touch"
                          isExternal
                          unstyled
                          to={t(
                            "accountSettings_org.paymentPlan.subscription.plan_notSelected.link.to",
                            "mailto:sales@payoutpartner.com"
                          )}
                        >
                          <Button
                            id="accountSettings__plans-get_in_touch"
                            size="md"
                          >
                            {t(
                              "accountSettings_org.paymentPlan.subscription.plan_notSelected.link.label",
                              "Get in touch"
                            )}
                          </Button>
                        </Link>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
