import { gql } from "@apollo/client";

import { AccountFragment } from "./account";

export const SIMPLIFIED_ACCOUNT_FRAGMENT = gql`
  fragment SimplifiedAccountFragment on Account {
    id
    slug
    accountType
    role
    tag
    email
    name
    description
    symbolDisplayPath
    phoneNumber
    organizationName
    organizationNumber
    primaryUser {
      id
      firstName
      lastName
      confirmed
      email
    }
  }
`;

export interface SimplifiedAccountFragment
  extends Pick<
    AccountFragment,
    | "id"
    | "slug"
    | "accountType"
    | "role"
    | "tag"
    | "email"
    | "name"
    | "description"
    | "phoneNumber"
    | "symbolDisplayPath"
    | "organizationName"
    | "organizationNumber"
    | "primaryUser"
  > {}
