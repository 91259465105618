import {
  // eslint-disable-next-line no-restricted-imports
  Trans as DefaultTrans,
  TransProps,
  // eslint-disable-next-line no-restricted-imports
  useTranslation,
} from "react-i18next";
import { getI18n } from "react-i18next";

import { NameSpace } from "./shared";

interface Props extends Omit<TransProps<string>, "ns" | "i18nKey"> {
  ns: NameSpace;
  i18nKey: string;
}

export default (props: Props): JSX.Element => {
  const i18n = getI18n();

  if (!i18n) return <>{props.children}</>;

  return <Trans {...props} />;
};

const Trans = ({ children, ...props }: Props): JSX.Element => {
  const { ready, t } = useTranslation();

  if (!ready) return <>{children}</>;

  return (
    <DefaultTrans {...props} t={t}>
      {children}
    </DefaultTrans>
  );
};
